@import '../variables/default.scss';
@import '../mixins/index.scss';

$at-form-bg-color: $color-bg;

.at-form {
  display: block;
  background-color: $at-form-bg-color;
  @include hairline-top-bottom();
}
