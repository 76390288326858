@mixin display-flex {
  display: flex;
}

@mixin flex-wrap($value: nowrap) {
  flex-wrap: $value;
}

@mixin align-items($value: stretch) {
  align-items: $value;
  @if $value == flex-start {
    -webkit-box-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
  } @else {
    -webkit-box-align: $value;
  }
}

@mixin align-content($value: flex-start) {
  align-content: $value;
}

@mixin justify-content($value: flex-start) {
  justify-content: $value;
  @if $value == flex-start {
    -webkit-box-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
  } @else {
    -webkit-box-pack: $value;
  }
}

/* Flex Item */
@mixin flex($fg: 1, $fs: null, $fb: null) {
  flex: $fg $fs $fb;
  -webkit-box-flex: $fg;
}

@mixin flex-order($n) {
  order: $n;
  -webkit-box-ordinal-group: $n;
}

@mixin align-self($value: auto) {
  align-self: $value;
}
