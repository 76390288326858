@import '../variables/default.scss';
@import '../mixins/index.scss';

.at-web-button {
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
  box-shadow: none;
}

.at-button {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0 $spacing-h-xl;
  height: $at-button-height;
  color: $color-text-base;
  font-size: $font-size-lg;
  line-height: $at-button-height - 2;
  text-align: center;
  border-radius: $border-radius-md;
  border: 1PX solid $color-border-base;
  box-sizing: border-box;
  @include line();

  &:active {
    opacity: $opacity-active;
  }

  /* elements */

  &__icon {
    display: inline-block;
    margin: 2PX 20px 0 20px;
  }

  &__text {
    display: inline;
  }

  &__wxbutton {
    position: absolute;
    padding: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    opacity: 0;
    z-index: 1;

    &::after {
      display: none;
    }
  }

  /* modifiers */
  &--active {
    opacity: $opacity-active;
  }

  &--disabled {
    opacity: $opacity-disabled;

    &:active {
      opacity: $opacity-disabled;
    }
  }

  &--primary {
    color: $color-text-base-inverse;
    border: 1PX solid $at-button-border-color-primary;
    background: $at-button-bg;
  }

  &--secondary {
    color: $at-button-color;
    border: 1PX solid $at-button-border-color-secondary;
    background-color: $color-white;
  }

  &--circle {
    border-radius: $at-button-height / 2;
    background-clip: border-box;
    overflow: hidden;
  }

  &--small {
    padding: 0 $spacing-h-md;
    width: auto;
    min-width: 100px;
    max-width: 710px;
    height: $at-button-height-sm;
    font-size: $font-size-base;
    line-height: $at-button-height-sm - 2;

    &.at-button--circle {
      border-radius: $at-button-height-sm / 2;
    }
  }

  &--full {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
}
