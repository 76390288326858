/**
 * 默认主题下 $color-border-light
 */

@mixin border-thin(
  $color: $color-border-light,
  $style: solid,
  $directions: top bottom right left,
  $width: 1px
) {
  @each $value in $directions {
    border-#{$value}: $width $color $style;
  }
}

@mixin border-thin-top(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, top, $width);
}

@mixin border-thin-left(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, left, $width);
}

@mixin border-thin-right(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, right, $width);
}

@mixin border-thin-bottom(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, bottom, $width);
}
