@import '../variables/default.scss';
@import '../mixins/index.scss';

$at-modal-duration: 200ms;

.at-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  transition: visibility $at-modal-duration ease-in;
  z-index: $zindex-modal;

  /* elements */
  &__overlay,
  &__container {
    opacity: 0;
    transition: opacity $at-modal-duration ease-in;
  }

  &__overlay {
    @include overlay;
  }

  &__container {
    @include absolute-center();

    width: $at-modal-width;
    border-radius: $border-radius-lg;
    background-color: $at-modal-bg-color;
    overflow: hidden;
  }

  &__header {
    @include hairline-bottom-relative($color: $color-grey-4);
    @include line(1);

    padding: $spacing-v-md $spacing-h-lg;
    color: $at-modal-header-text-color;
    font-size: $font-size-lg;
    text-align: center;
  }

  &__content {
    padding: $spacing-h-xl $spacing-h-lg;
    min-height: 180px;
    max-height: 840px;
    color: $at-modal-content-text-color;
    font-size: $font-size-base;
    box-sizing: content-box;
    width: auto;
  }

  &__footer {
    @include hairline-top-relative($color: $color-grey-4);

    .at-modal__action {
      @include display-flex;

      & > button {
        @include flex(auto);
        @include line(1);
        @include active;

        margin-top: 0;
        min-width: 160px;
        height: 88px;
        color: $at-modal-btn-default-color;
        font-size: $font-size-base;
        line-height: 86px;
        outline: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;

        &::after {
          border: 0;
          border-radius: 0;
          width: initial;
          height: initial;
        }

        &:not(:first-child) {
          @include hairline-left-relative($color: $color-grey-4);
        }

        &:last-child {
          color: $at-modal-btn-confirm-color;
        }
      }
    }

    &--simple .at-modal__action > button:last-child:nth-child(2) {
      color: $at-modal-btn-confirm-color;
    }
  }

  /* modifiers */
  &--active {
    visibility: visible;

    .at-modal__overlay,
    .at-modal__container {
      opacity: 1;
    }
  }
}
