.at-swipe-action {
  position: relative;
  overflow: hidden;

  /* elements */
  &__content {
    position: relative;
    font-size: 12px;
    background-color: #fff;
    z-index: 2;

    &.animtion {
      transition: transform 300ms cubic-bezier(0.36, 0.66, 0.04, 1);
    }
  }

  &__option {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 100%;
    color: #fff !important;
    font-size: 14px;
    background-color: #000;
  }

  &__options {
    display: inline-flex;
    position: absolute;
    color: #fff;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1;
  }
}
/**
 * Default variables
 */

/**
 * Mixes a color with white. It's different from lighten()
 *
 * @param {color} $color
 * @param {number (percentage)} $percent [The amout of white to be mixed in]
 * @return {color}
 *
 * @example
 *   .element {
 *     background-color: tint(#6ecaa6 , 40%);
 *   }
 *
 *   // CSS Output
 *   .element {
 *     background-color: #a8dfc9;
 *   }
 */
@function tint(
  $color,
  $percent
) {
  @return mix(#FFF, $color, $percent);
}


$hd: 2 !default; // 基本单位

/* The Color of O2Team Brand */
$color-brand: #6190E8 !default;
$color-brand-light: #78A4F4 !default;
$color-brand-dark: #346FC2 !default;

/* Color */
$color-success: #13CE66 !default;
$color-error: #FF4949 !default;
$color-warning: #FFC82C !default;
$color-info: #78A4FA !default;

/* Color Palette */
$color-black-0: #000 !default;
$color-black-1: #333 !default;
$color-black-2: #7F7F7F !default;
$color-black-3: #B2B2B2 !default;

$color-grey-0: #333 !default;
$color-grey-1: #666 !default;
$color-grey-2: #999 !default;
$color-grey-3: #CCC !default;
$color-grey-4: #E5E5E5 !default;
$color-grey-5: #F0F0F0 !default;
$color-grey-6: #F7F7F7 !default;

$color-white: #FFF !default;

/* Text Color */
$color-text-base: #333 !default; // 文字的基本色
$color-text-base-inverse: #FFF !default; // 反色
$color-text-secondary: #36D57D !default; // 辅助色
$color-text-placeholder: #C9C9C9 !default;
$color-text-disabled: #CCC !default;
$color-text-title: #2C405A !default; // 文章标题
$color-text-paragraph: #3F536E !default; // 文章段落

/* Link */
$color-link: #6190E8 !default;
$color-link-hover: #79A1EB !default;
$color-link-active: #4F7DE2 !default;
$color-link-disabled: #BFBFBF !default;

/* 背景色 */
$color-bg: #FFF !default;
$color-bg-base: #FAFBFC !default;
$color-bg-light: #ECF5FD !default;
$color-bg-lighter: tint($color-bg-light, 50%) !default;
$color-bg-grey: #F7F7F7 !default;

/* 边框颜色 */
$color-border-base: #C5D9E8 !default;
$color-border-split: tint($color-border-base, 20%) !default; // 分割线
$color-border-light: tint($color-border-base, 30%) !default;
$color-border-lighter: tint($color-border-base, 50%) !default;
$color-border-lightest: tint($color-border-base, 80%) !default;
$color-border-grey: #CCC !default;

/* 图标颜色 */
$color-icon-base: #CCC !default;

/* Border Radius */
$border-radius-sm: 2px * $hd !default;
$border-radius-md: 4px * $hd !default;
$border-radius-lg: 6px * $hd !default;
$border-radius-circle: 50% !default;

/* 透明度 */
$opacity-active: 0.6 !default; // Button 等组件点击态额透明度
$opacity-disabled: 0.3 !default; // Button 等组件禁用态的透明度

/* Font */
$font-size-xs: 10px * $hd !default; // 非常用字号，用于标签
$font-size-sm: 12px * $hd !default; // 用于辅助信息
$font-size-base: 14px * $hd !default; // 常用字号
$font-size-lg: 16px * $hd !default; // 常规标题
$font-size-xl: 18px * $hd !default; // 大标题
$font-size-xxl: 20px * $hd !default; // 用于大号的数字

/* Line Height */
$line-height-base: 1 !default; // 单行
$line-height-en: 1.3 !default; // 英文多行
$line-height-zh: 1.5 !default; // 中文多行

/* 水平间距 */
$spacing-h-sm: 5px * $hd !default;
$spacing-h-md: 8px * $hd !default;
$spacing-h-lg: 12px * $hd !default;
$spacing-h-xl: 16px * $hd !default;

/* 垂直间距 */
$spacing-v-xs: 3px * $hd !default;
$spacing-v-sm: 6px * $hd !default;
$spacing-v-md: 9px * $hd !default;
$spacing-v-lg: 12px * $hd !default;
$spacing-v-xl: 15px * $hd !default;

/* 图标尺寸 */
$icon-size-sm: 18px * $hd !default;
$icon-size-md: 22px * $hd !default;
$icon-size-lg: 36px * $hd !default;

/* z-index */
$zindex-divider: 100 !default;
$zindex-steps: 500 !default;
$zindex-tab: 600 !default;
$zindex-form: 700 !default;
$zindex-nav: 800 !default;
$zindex-search-bar: 800 !default;
$zindex-indexes: 805 !default;
$zindex-flot-layout: 810 !default;
$zindex-drawer: 900 !default;
$zindex-modal: 1000 !default;
$zindex-action-sheet: 1010 !default;
$zindex-picker: 1010 !default;
$zindex-curtain: 1080 !default;
$zindex-message: 1090 !default;
$zindex-toast: 1090 !default;

/* timing function */
$timing-func: cubic-bezier(0.36, 0.66, 0.04, 1) !default;

/**
* CSS cubic-bezier timing functions
* http://bourbon.io/docs/#timing-functions
*/
$ease-in-quad: cubic-bezier(0.550, 0.085, 0.680, 0.530) !default;
$ease-in-cubic: cubic-bezier(0.550, 0.055, 0.675, 0.190) !default;
$ease-in-quart: cubic-bezier(0.895, 0.030, 0.685, 0.220) !default;
$ease-in-quint: cubic-bezier(0.755, 0.050, 0.855, 0.060) !default;
$ease-in-sine: cubic-bezier(0.470, 0.000, 0.745, 0.715) !default;
$ease-in-expo: cubic-bezier(0.950, 0.050, 0.795, 0.035) !default;
$ease-in-circ: cubic-bezier(0.600, 0.040, 0.980, 0.335) !default;
$ease-in-back: cubic-bezier(0.600, -0.280, 0.735, 0.045) !default;

$ease-out-quad: cubic-bezier(0.250, 0.460, 0.450, 0.940) !default;
$ease-out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000) !default;
$ease-out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000) !default;
$ease-out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000) !default;
$ease-out-sine: cubic-bezier(0.390, 0.575, 0.565, 1.000) !default;
$ease-out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000) !default;
$ease-out-circ: cubic-bezier(0.075, 0.820, 0.165, 1.000) !default;
$ease-out-back: cubic-bezier(0.175, 0.885, 0.320, 1.275) !default;

$ease-in-out-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955) !default;
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000) !default;
$ease-in-out-quart: cubic-bezier(0.770, 0.000, 0.175, 1.000) !default;
$ease-in-out-quint: cubic-bezier(0.860, 0.000, 0.070, 1.000) !default;
$ease-in-out-sine: cubic-bezier(0.445, 0.050, 0.550, 0.950) !default;
$ease-in-out-expo: cubic-bezier(1.000, 0.000, 0.000, 1.000) !default;
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.150, 0.860) !default;
$ease-in-out-back: cubic-bezier(0.680, -0.550, 0.265, 1.550) !default;

/**
 * 组件变量
 */

/* Accordion */
$at-accordion-color-arrow: $color-grey-3 !default;

/* Activity Indicator */
$at-activity-indicator-font-size: 28px !default;
$at-activity-indicator-font-color: $color-grey-2 !default;

/* Avatar */
$at-avatar-color: $color-white !default;
$at-avatar-bg-color: $color-grey-4 !default;
$at-avatar-size-sm: 80px !default;
$at-avatar-size-md: 100px !default;
$at-avatar-size-lg: 120px !default;

/* Badge */
$at-badge-color: $color-white !default;
$at-badge-bg-color: $color-error !default;
$at-badge-bg: $at-badge-bg-color !default;
$at-badge-font-size: $font-size-xs !default;
$at-badge-dot-size: 20px !default;

/* Button */
$at-button-height: 92px !default;
$at-button-height-sm: 60px !default;
$at-button-color: $color-brand !default;
$at-button-border-color-primary: $color-brand !default;
$at-button-border-color-secondary: $color-brand !default;
$at-button-bg: $at-button-color !default;

/* Float Button */
$at-fab-size: 56px * $hd !default;
$at-fab-size-sm: 40px * $hd !default;
$at-fab-icon-size: 24px * $hd !default;
$at-fab-bg-color: $color-brand;
$at-fab-bg-color-active: $color-brand-dark;
$at-fab-box-shadow:
  0 6px 10px -2px rgba(0, 0, 0, 0.2),
  0 12px 20px 0 rgba(0, 0, 0, 0.14),
  0 2px 36px 0 rgba(0, 0, 0, 0.12) !default;
$at-fab-box-shadow-active:
  0 14px 16px -8px rgba(0, 0, 0, 0.2),
  0 24px 34px 4px rgba(0, 0, 0, 0.14),
  0 10px 44px 8px rgba(0, 0, 0, 0.12) !default;

/* Calendar */
$at-calendar-day-size: 72px !default;
$at-calendar-mark-size: 8px !default;
$at-calendar-header-color: #B8BFC6 !default;
$at-calendar-main-color: $color-brand !default;
$at-calendar-day-color: #7C86A2 !default;

/* Card */
$at-card-thumb-size: 32px !default;
$at-card-icon-size: 32px !default;
$at-card-title-color: $color-text-title !default;
$at-card-extra-color: $color-text-title !default;
$at-card-info-color: $color-text-base !default;
$at-card-note-color: $color-grey-2 !default;

/* Checkbox */
$at-checkbox-circle-size: 40px !default;
$at-checkbox-icon-size: $font-size-sm !default;
$at-checkbox-icon-color: $color-brand !default;
$at-checkbox-icon-color-checked: $color-white !default;
$at-checkbox-title-color: $color-text-base !default;
$at-checkbox-title-font-size: $font-size-lg !default;
$at-checkbox-desc-font-size: $font-size-sm !default;
$at-checkbox-desc-color: $color-grey-2 !default;

/* Countdown */
$at-countdown-font-size: $font-size-lg !default;
$at-countdown-num-color: $color-text-base !default;
$at-countdown-card-num-color: #FF4949 !default;
$at-countdown-card-num-bg-color: $color-white !default;

/* Curtain */
$at-curtain-btn-color: $color-white !default;

/* Divider */
$at-divider-height: 112px;
$at-divider-content-color: $color-brand !default;
$at-divider-font-size: $font-size-lg !default;
$at-divider-line-color: $color-grey-3 !default;

/* Drawer */
$at-drawer-content-width: 460px !default;

/* FloatLayout */
$float-layout-height-min: 600px !default;
$float-layout-height-max: 950px !default;
$float-layout-header-bg-color: $color-bg-grey !default;
$float-layout-title-color: $color-text-base !default;
$float-layout-title-font-size: $font-size-lg !default;
$float-layout-btn-color: $color-grey-3 !default;

/* Grid */
$at-grid-text-color: $color-text-base !default;
$at-grid-font-size: $font-size-lg !default;
$at-grid-img-size: 80px !default;
$at-gird-img-size-sm: 50px !default;

/* ImagePicker */
$at-image-picker-btn-add-color: $color-grey-3 !default;
$at-image-picker-btn-remove-color: $color-white !default;
$at-image-picker-btn-remove-bg-color: $color-grey-2 !default;

/* Indexes */
$at-indexes-nav-color: $color-link !default;
$at-indexes-nav-font-size: $font-size-sm !default;
$at-indexes-title-color: $color-black-2 !default;
$at-indexes-title-font-size: $font-size-sm !default;
$at-indexes-title-bg-color: $color-grey-6 !default;

/* InputNumber */
$at-input-number-text-color: $color-text-base !default;
$at-input-number-font-size: $font-size-base !default;
$at-input-number-font-size-lg: $font-size-xl !default;
$at-input-number-btn-color: $color-brand !default;
$at-input-number-btn-size: 30px !default;
$at-input-number-btn-size-lg: 36px !default;
$at-input-number-width-min: 80px !default;
$at-input-number-width-min-lg: 120px !default;

/* Input */
$at-input-label-color: $color-text-base !default;
$at-input-text-color: $color-text-base !default;
$at-input-font-size: $font-size-lg !default;
$at-input-placeholder-color: $color-grey-3 !default;

/* List */
$at-list-thumb-size: 56px !default;
$at-list-arrow-color: $color-grey-3 !default;
$at-list-text-color: $color-text-base !default;
$at-list-content-color: $color-grey-2 !default;
$at-list-extra-color: $color-grey-2 !default;
$at-list-extra-width: 235px !default;

/* LoadMore */
$at-load-more-height: 80PX !default;
$at-load-more-tips-color: $color-grey-1 !default;
$at-load-more-tips-size: $font-size-lg !default;

/* Loading */
$at-loading-size: 36px !default;
$at-loading-color: $color-brand !default;

/* Message */
$at-message-color: $color-white !default;
$at-message-font-size: $font-size-base !default;
$at-message-bg-color: $color-info !default;

/* Modal */
$at-modal-width: 540px !default;
$at-modal-header-text-color: $color-text-base !default;
$at-modal-content-text-color: $color-text-base !default;
$at-modal-btn-default-color: $color-text-base !default;
$at-modal-btn-confirm-color: $color-brand !default;
$at-modal-bg-color: $color-white !default;

/* NavBar */
$at-nav-bar-title-color: $color-text-base !default;
$at-nav-bar-link-color: $color-brand !default;

/* NoticeBar */
$at-noticebar-text-color: #DE8C17 !default;
$at-noticebar-bg-color: #FCF6ED !default;
$at-noticebar-font-size: $font-size-sm !default;
$at-noticebar-icon-size: 30px !default;
$at-noticebar-btn-close-size: 32px !default;
$at-noticebar-btn-close-color: $color-grey-3 !default;

/* Pagination */
$at-pagination-margin: 40px !default;
$at-pagination-num-color: $color-text-base !default;
$at-pagination-num-font-size: $font-size-base !default;
$at-pagination-current-num-color: $color-brand !default;
$at-pagination-icon-color: $color-text-base !default;
$at-pagination-icon-font-size: 32px !default;

/* Progress */
$at-progress-height: 16px !default;
$at-progress-text-size: $font-size-sm !default;
$at-progress-icon-size: $font-size-xl !default;
$at-progress-inner-bg-color: $color-grey-6 !default;
$at-progress-bar-bg-color: $color-brand-light !default;
$at-progress-bar-bg-color-active: $color-white !default;

/* Radio */
$at-radio-title-color: $color-text-base !default;
$at-radio-title-size: $font-size-lg !default;
$at-radio-desc-color: $color-grey-2 !default;
$at-radio-desc-size: $font-size-sm !default;
$at-radio-check-color: $color-brand !default;

/* Range */
$at-range-slider-size: 28PX !default;
$at-range-rail-height: 2PX !default;
$at-range-rail-bg-color: #E9E9E9 !default;
$at-range-track-bg-color: $color-brand !default;
$at-range-slider-color: $color-white !default;
$at-range-slider-shadow: 0 0 4PX 0 rgba(0, 0, 0, 0.2) !default;

/* Rate */
$at-rate-icon-size: 20PX !default;
$at-rate-star-color: #ECECEC !default;
$at-rate-star-color-on: #FFCA3E !default;

/* SearchBar */
$at-search-bar-btn-color: $color-white !default;
$at-search-bar-btn-bg-color: $color-brand !default;

/* SegmentedControl */
$at-segmented-control-color: $color-brand !default;
$at-segmented-control-color-active: $color-white !default;
$at-segmented-control-bg-color: transparent !default;
$at-segmented-control-font-size: $font-size-base !default;

/* Slider */
$at-slider-text-color: $color-grey-2 !default;
$at-slider-text-size: $font-size-base !default;

/* Steps */
$at-steps-circle-size: 56px !default;
$at-steps-icon-size: $font-size-sm !default;
$at-steps-color: $color-white !default;
$at-steps-color-active: $color-grey-2 !default;
$at-steps-bg-color: $color-grey-4 !default;
$at-steps-bg-color-active: $color-brand !default;
$at-steps-line-color: $color-grey-3 !default;
$at-steps-title-color: $color-black-0 !default;
$at-steps-title-size: $font-size-lg !default;
$at-steps-desc-color: $color-grey-3 !default;
$at-steps-desc-size: $font-size-sm !default;

/* SwipeAction */
$at-swipe-action-color: $color-white !default;
$at-swipe-action-font-size: $font-size-base !default;
$at-swipe-action-bg-color: $color-white !default;
$at-swipe-action-option-bg-color: $color-grey-2 !default;

/* Switch */
$at-switch-title-color: $color-text-base !default;
$at-switch-title-size: $font-size-base !default;

/* TabBar */
$at-tab-bar-bg-color: $color-white !default;
$at-tab-bar-color: $color-text-base !default;
$at-tab-bar-color-active: $color-brand !default;
$at-tab-bar-font-size: $font-size-base !default;
$at-tab-bar-icon-color: $color-grey-0 !default;
$at-tab-bar-icon-font-size: 48px !default;
$at-tab-bar-icon-image-size: 50px !default;

/* Tabs */
$at-tabs-color: $color-text-base !default;
$at-tabs-color-active: $color-brand !default;
$at-tabs-font-size: $font-size-base !default;
$at-tabs-line-height: 1PX !default;
$at-tabs-underline-color: $color-grey-5 !default;
$at-tabs-bg-color: $color-bg !default;

/* Tag */
$at-tag-height: 60px !default;
$at-tag-height-sm: 40px !default;
$at-tag-color: $color-grey-1 !default;
$at-tag-color-primary: $color-grey-1 !default;
$at-tag-color-active: $color-brand-light !default;
$at-tag-color-primary-active: $color-text-base-inverse !default;
$at-tag-font-size: $font-size-base !default;
$at-tag-font-size-sm: $font-size-xs !default;
$at-tag-bg-color: $color-bg-grey !default;
$at-tag-bg-color-primary: $color-bg-grey !default;
$at-tag-bg-color-active: $color-white !default;
$at-tag-bg-color-primary-active: $at-tag-color-active !default;
$at-tag-border-color: $at-tag-bg-color !default;
$at-tag-border-color-primary: $at-tag-bg-color !default;
$at-tag-border-color-active: $at-tag-color-active !default;

/* Textarea */
$at-textarea-font-size: $font-size-lg !default;
$at-textarea-tips-color: $color-text-placeholder !default;
$at-textarea-tips-size: $font-size-base !default;

/* Timeline */
$at-timeline-offset-left: 40px !default;
$at-timeline-title-color: $color-grey-0 !default;
$at-timeline-title-font-size: $font-size-base !default;
$at-timeline-desc-color: $color-grey-1 !default;
$at-timeline-desc-font-size: $font-size-sm !default;
$at-timeline-dot-size: 24px !default;
$at-timeline-dot-color: $color-bg !default;
$at-timeline-dot-border-color: $color-brand-light !default;
$at-timeline-line-color: $color-border-lighter !default;

/**
 * Mixins
 */

/* library */
/**
 * 元素居中定位
 */
@mixin absolute-center($pos: absolute) {
  position: $pos;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/**
 * @example scss
 *
 * .element {
 *   @include clearfix;
 * }
 *
 * // CSS Output
 * .element::after {
 *   clear: both;
 *   content: '';
 *   display: block;
 * }
 */
@mixin clearfix {
  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

@mixin line($num: 1) {
  overflow: hidden;
  text-overflow: ellipsis;

  @if ($num == 1) {
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $num;

    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }
}

/**
 * 通用的遮罩
 */
@mixin overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba($color: #000, $alpha: 0.3);
}

/**
 * Mixes a color with black. It's different from darken()
 *
 * @param {color} $color
 * @param {number (percentage)} $percent [The amount of black to be mixed in]
 * @return {color}
 *
 * @example
 *   .element {
 *     background-color: shade(#ffbb52, 60%);
 *   }
 *
 *   // CSS Output
 *   .element {
 *     background-color: #664a20;
 *   }
 */
@function shade(
  $color,
  $percent
) {
  @return mix(#000, $color, $percent);
}

/**
 * Mixes a color with white. It's different from lighten()
 *
 * @param {color} $color
 * @param {number (percentage)} $percent [The amout of white to be mixed in]
 * @return {color}
 *
 * @example
 *   .element {
 *     background-color: tint(#6ecaa6 , 40%);
 *   }
 *
 *   // CSS Output
 *   .element {
 *     background-color: #a8dfc9;
 *   }
 */
@function tint(
  $color,
  $percent
) {
  @return mix(#FFF, $color, $percent);
}

@mixin display-flex {
  display: flex;
}

@mixin flex-wrap($value: nowrap) {
  flex-wrap: $value;
}

@mixin align-items($value: stretch) {
  align-items: $value;
  @if $value == flex-start {
    -webkit-box-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
  } @else {
    -webkit-box-align: $value;
  }
}

@mixin align-content($value: flex-start) {
  align-content: $value;
}

@mixin justify-content($value: flex-start) {
  justify-content: $value;
  @if $value == flex-start {
    -webkit-box-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
  } @else {
    -webkit-box-pack: $value;
  }
}

/* Flex Item */
@mixin flex($fg: 1, $fs: null, $fb: null) {
  flex: $fg $fs $fb;
  -webkit-box-flex: $fg;
}

@mixin flex-order($n) {
  order: $n;
  -webkit-box-ordinal-group: $n;
}

@mixin align-self($value: auto) {
  align-self: $value;
}

/**
 * 默认主题下 $color-border-light
 */

@mixin border-thin(
  $color: $color-border-light,
  $style: solid,
  $directions: top bottom right left,
  $width: 1px
) {
  @each $value in $directions {
    border-#{$value}: $width $color $style;
  }
}

@mixin border-thin-top(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, top, $width);
}

@mixin border-thin-left(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, left, $width);
}

@mixin border-thin-right(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, right, $width);
}

@mixin border-thin-bottom(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, bottom, $width);
}

/**
 * 点击态
 */
@mixin active {
  transition: background-color 0.3s;

  &:active {
    background-color: $color-grey-5;
  }
}

/**
 * 禁止态
 */
@mixin disabled {
  color: #bbb;
}

/**
 * 设置placeholder 颜色
 */
@mixin placeholder($color: $color-grey-3) {
  @at-root .placeholder {
    color: $color;
  }

  &::placeholder {
    color: $color;
  }
}

// 修复小元素文本垂直居中
@mixin alignhack($position: before, $margintop: 1px) {
  &::#{$position} {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    margin-top: $margintop;
  }
}

/**
 * 默认主题下 $color-border-light
 */

@mixin hairline-common() {
  content: '';
  position: absolute;
  transform-origin: center;
  box-sizing: border-box;
  pointer-events: none;
}

@mixin hairline-base(
  $color: $color-border-light,
  $style: solid
) {
  @include hairline-common();

  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  border: 0 $style $color;
  transform: scale(0.5);
}

@mixin hairline-surround(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-width: $width;
  }
}

@mixin hairline-top(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-top-width: $width;
  }
}

@mixin hairline-bottom(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-bottom-width: $width;
  }
}

@mixin hairline-left(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-left-width: $width;
  }
}

@mixin hairline-right(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-right-width: $width;
  }
}

@mixin hairline-top-bottom(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-top-width: $width;
    border-bottom-width: $width;
  }
}

@mixin hairline-bottom-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $left: 0
) {
  position: relative;

  &::after {
    @include hairline-common();

    top: auto;
    left: $left;
    right: 0;
    bottom: 0;
    transform: scaleY(0.5);
    border-bottom: $width $style $color;
  }
}

@mixin hairline-top-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $left: 0
) {
  position: relative;

  &::before {
    @include hairline-common();

    top: 0;
    left: $left;
    right: 0;
    bottom: auto;
    transform: scaleY(0.5);
    border-top: $width $style $color;
  }
}

@mixin hairline-left-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $top: 0
) {
  position: relative;

  &::after {
    @include hairline-common();

    top: $top;
    left: 0;
    right: auto;
    bottom: 0;
    transform: scaleX(0.5);
    border-left: $width $style $color;
  }
}

@mixin hairline-right-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $top: 0
) {
  position: relative;

  &::after {
    @include hairline-common();

    top: $top;
    left: auto;
    right: 0;
    bottom: 0;
    transform: scaleX(0.5);
    border-right: $width $style $color;
  }
}



$at-modal-duration: 200ms;

.at-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  transition: visibility $at-modal-duration ease-in;
  z-index: $zindex-modal;

  /* elements */
  &__overlay,
  &__container {
    opacity: 0;
    transition: opacity $at-modal-duration ease-in;
  }

  &__overlay {
    @include overlay;
  }

  &__container {
    @include absolute-center();

    width: $at-modal-width;
    border-radius: $border-radius-lg;
    background-color: $at-modal-bg-color;
    overflow: hidden;
  }

  &__header {
    @include hairline-bottom-relative($color: $color-grey-4);
    @include line(1);

    padding: $spacing-v-md $spacing-h-lg;
    color: $at-modal-header-text-color;
    font-size: $font-size-lg;
    text-align: center;
  }

  &__content {
    padding: $spacing-h-xl $spacing-h-lg;
    min-height: 180px;
    max-height: 840px;
    color: $at-modal-content-text-color;
    font-size: $font-size-base;
    box-sizing: content-box;
    width: auto;
  }

  &__footer {
    @include hairline-top-relative($color: $color-grey-4);

    .at-modal__action {
      @include display-flex;

      & > button {
        @include flex(auto);
        @include line(1);
        @include active;

        margin-top: 0;
        min-width: 160px;
        height: 88px;
        color: $at-modal-btn-default-color;
        font-size: $font-size-base;
        line-height: 86px;
        outline: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;

        &::after {
          border: 0;
          border-radius: 0;
          width: initial;
          height: initial;
        }

        &:not(:first-child) {
          @include hairline-left-relative($color: $color-grey-4);
        }

        &:last-child {
          color: $at-modal-btn-confirm-color;
        }
      }
    }

    &--simple .at-modal__action > button:last-child:nth-child(2) {
      color: $at-modal-btn-confirm-color;
    }
  }

  /* modifiers */
  &--active {
    visibility: visible;

    .at-modal__overlay,
    .at-modal__container {
      opacity: 1;
    }
  }
}
/**
 * Default variables
 */

/**
 * Mixes a color with white. It's different from lighten()
 *
 * @param {color} $color
 * @param {number (percentage)} $percent [The amout of white to be mixed in]
 * @return {color}
 *
 * @example
 *   .element {
 *     background-color: tint(#6ecaa6 , 40%);
 *   }
 *
 *   // CSS Output
 *   .element {
 *     background-color: #a8dfc9;
 *   }
 */
@function tint(
  $color,
  $percent
) {
  @return mix(#FFF, $color, $percent);
}


$hd: 2 !default; // 基本单位

/* The Color of O2Team Brand */
$color-brand: #6190E8 !default;
$color-brand-light: #78A4F4 !default;
$color-brand-dark: #346FC2 !default;

/* Color */
$color-success: #13CE66 !default;
$color-error: #FF4949 !default;
$color-warning: #FFC82C !default;
$color-info: #78A4FA !default;

/* Color Palette */
$color-black-0: #000 !default;
$color-black-1: #333 !default;
$color-black-2: #7F7F7F !default;
$color-black-3: #B2B2B2 !default;

$color-grey-0: #333 !default;
$color-grey-1: #666 !default;
$color-grey-2: #999 !default;
$color-grey-3: #CCC !default;
$color-grey-4: #E5E5E5 !default;
$color-grey-5: #F0F0F0 !default;
$color-grey-6: #F7F7F7 !default;

$color-white: #FFF !default;

/* Text Color */
$color-text-base: #333 !default; // 文字的基本色
$color-text-base-inverse: #FFF !default; // 反色
$color-text-secondary: #36D57D !default; // 辅助色
$color-text-placeholder: #C9C9C9 !default;
$color-text-disabled: #CCC !default;
$color-text-title: #2C405A !default; // 文章标题
$color-text-paragraph: #3F536E !default; // 文章段落

/* Link */
$color-link: #6190E8 !default;
$color-link-hover: #79A1EB !default;
$color-link-active: #4F7DE2 !default;
$color-link-disabled: #BFBFBF !default;

/* 背景色 */
$color-bg: #FFF !default;
$color-bg-base: #FAFBFC !default;
$color-bg-light: #ECF5FD !default;
$color-bg-lighter: tint($color-bg-light, 50%) !default;
$color-bg-grey: #F7F7F7 !default;

/* 边框颜色 */
$color-border-base: #C5D9E8 !default;
$color-border-split: tint($color-border-base, 20%) !default; // 分割线
$color-border-light: tint($color-border-base, 30%) !default;
$color-border-lighter: tint($color-border-base, 50%) !default;
$color-border-lightest: tint($color-border-base, 80%) !default;
$color-border-grey: #CCC !default;

/* 图标颜色 */
$color-icon-base: #CCC !default;

/* Border Radius */
$border-radius-sm: 2px * $hd !default;
$border-radius-md: 4px * $hd !default;
$border-radius-lg: 6px * $hd !default;
$border-radius-circle: 50% !default;

/* 透明度 */
$opacity-active: 0.6 !default; // Button 等组件点击态额透明度
$opacity-disabled: 0.3 !default; // Button 等组件禁用态的透明度

/* Font */
$font-size-xs: 10px * $hd !default; // 非常用字号，用于标签
$font-size-sm: 12px * $hd !default; // 用于辅助信息
$font-size-base: 14px * $hd !default; // 常用字号
$font-size-lg: 16px * $hd !default; // 常规标题
$font-size-xl: 18px * $hd !default; // 大标题
$font-size-xxl: 20px * $hd !default; // 用于大号的数字

/* Line Height */
$line-height-base: 1 !default; // 单行
$line-height-en: 1.3 !default; // 英文多行
$line-height-zh: 1.5 !default; // 中文多行

/* 水平间距 */
$spacing-h-sm: 5px * $hd !default;
$spacing-h-md: 8px * $hd !default;
$spacing-h-lg: 12px * $hd !default;
$spacing-h-xl: 16px * $hd !default;

/* 垂直间距 */
$spacing-v-xs: 3px * $hd !default;
$spacing-v-sm: 6px * $hd !default;
$spacing-v-md: 9px * $hd !default;
$spacing-v-lg: 12px * $hd !default;
$spacing-v-xl: 15px * $hd !default;

/* 图标尺寸 */
$icon-size-sm: 18px * $hd !default;
$icon-size-md: 22px * $hd !default;
$icon-size-lg: 36px * $hd !default;

/* z-index */
$zindex-divider: 100 !default;
$zindex-steps: 500 !default;
$zindex-tab: 600 !default;
$zindex-form: 700 !default;
$zindex-nav: 800 !default;
$zindex-search-bar: 800 !default;
$zindex-indexes: 805 !default;
$zindex-flot-layout: 810 !default;
$zindex-drawer: 900 !default;
$zindex-modal: 1000 !default;
$zindex-action-sheet: 1010 !default;
$zindex-picker: 1010 !default;
$zindex-curtain: 1080 !default;
$zindex-message: 1090 !default;
$zindex-toast: 1090 !default;

/* timing function */
$timing-func: cubic-bezier(0.36, 0.66, 0.04, 1) !default;

/**
* CSS cubic-bezier timing functions
* http://bourbon.io/docs/#timing-functions
*/
$ease-in-quad: cubic-bezier(0.550, 0.085, 0.680, 0.530) !default;
$ease-in-cubic: cubic-bezier(0.550, 0.055, 0.675, 0.190) !default;
$ease-in-quart: cubic-bezier(0.895, 0.030, 0.685, 0.220) !default;
$ease-in-quint: cubic-bezier(0.755, 0.050, 0.855, 0.060) !default;
$ease-in-sine: cubic-bezier(0.470, 0.000, 0.745, 0.715) !default;
$ease-in-expo: cubic-bezier(0.950, 0.050, 0.795, 0.035) !default;
$ease-in-circ: cubic-bezier(0.600, 0.040, 0.980, 0.335) !default;
$ease-in-back: cubic-bezier(0.600, -0.280, 0.735, 0.045) !default;

$ease-out-quad: cubic-bezier(0.250, 0.460, 0.450, 0.940) !default;
$ease-out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000) !default;
$ease-out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000) !default;
$ease-out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000) !default;
$ease-out-sine: cubic-bezier(0.390, 0.575, 0.565, 1.000) !default;
$ease-out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000) !default;
$ease-out-circ: cubic-bezier(0.075, 0.820, 0.165, 1.000) !default;
$ease-out-back: cubic-bezier(0.175, 0.885, 0.320, 1.275) !default;

$ease-in-out-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955) !default;
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000) !default;
$ease-in-out-quart: cubic-bezier(0.770, 0.000, 0.175, 1.000) !default;
$ease-in-out-quint: cubic-bezier(0.860, 0.000, 0.070, 1.000) !default;
$ease-in-out-sine: cubic-bezier(0.445, 0.050, 0.550, 0.950) !default;
$ease-in-out-expo: cubic-bezier(1.000, 0.000, 0.000, 1.000) !default;
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.150, 0.860) !default;
$ease-in-out-back: cubic-bezier(0.680, -0.550, 0.265, 1.550) !default;

/**
 * 组件变量
 */

/* Accordion */
$at-accordion-color-arrow: $color-grey-3 !default;

/* Activity Indicator */
$at-activity-indicator-font-size: 28px !default;
$at-activity-indicator-font-color: $color-grey-2 !default;

/* Avatar */
$at-avatar-color: $color-white !default;
$at-avatar-bg-color: $color-grey-4 !default;
$at-avatar-size-sm: 80px !default;
$at-avatar-size-md: 100px !default;
$at-avatar-size-lg: 120px !default;

/* Badge */
$at-badge-color: $color-white !default;
$at-badge-bg-color: $color-error !default;
$at-badge-bg: $at-badge-bg-color !default;
$at-badge-font-size: $font-size-xs !default;
$at-badge-dot-size: 20px !default;

/* Button */
$at-button-height: 92px !default;
$at-button-height-sm: 60px !default;
$at-button-color: $color-brand !default;
$at-button-border-color-primary: $color-brand !default;
$at-button-border-color-secondary: $color-brand !default;
$at-button-bg: $at-button-color !default;

/* Float Button */
$at-fab-size: 56px * $hd !default;
$at-fab-size-sm: 40px * $hd !default;
$at-fab-icon-size: 24px * $hd !default;
$at-fab-bg-color: $color-brand;
$at-fab-bg-color-active: $color-brand-dark;
$at-fab-box-shadow:
  0 6px 10px -2px rgba(0, 0, 0, 0.2),
  0 12px 20px 0 rgba(0, 0, 0, 0.14),
  0 2px 36px 0 rgba(0, 0, 0, 0.12) !default;
$at-fab-box-shadow-active:
  0 14px 16px -8px rgba(0, 0, 0, 0.2),
  0 24px 34px 4px rgba(0, 0, 0, 0.14),
  0 10px 44px 8px rgba(0, 0, 0, 0.12) !default;

/* Calendar */
$at-calendar-day-size: 72px !default;
$at-calendar-mark-size: 8px !default;
$at-calendar-header-color: #B8BFC6 !default;
$at-calendar-main-color: $color-brand !default;
$at-calendar-day-color: #7C86A2 !default;

/* Card */
$at-card-thumb-size: 32px !default;
$at-card-icon-size: 32px !default;
$at-card-title-color: $color-text-title !default;
$at-card-extra-color: $color-text-title !default;
$at-card-info-color: $color-text-base !default;
$at-card-note-color: $color-grey-2 !default;

/* Checkbox */
$at-checkbox-circle-size: 40px !default;
$at-checkbox-icon-size: $font-size-sm !default;
$at-checkbox-icon-color: $color-brand !default;
$at-checkbox-icon-color-checked: $color-white !default;
$at-checkbox-title-color: $color-text-base !default;
$at-checkbox-title-font-size: $font-size-lg !default;
$at-checkbox-desc-font-size: $font-size-sm !default;
$at-checkbox-desc-color: $color-grey-2 !default;

/* Countdown */
$at-countdown-font-size: $font-size-lg !default;
$at-countdown-num-color: $color-text-base !default;
$at-countdown-card-num-color: #FF4949 !default;
$at-countdown-card-num-bg-color: $color-white !default;

/* Curtain */
$at-curtain-btn-color: $color-white !default;

/* Divider */
$at-divider-height: 112px;
$at-divider-content-color: $color-brand !default;
$at-divider-font-size: $font-size-lg !default;
$at-divider-line-color: $color-grey-3 !default;

/* Drawer */
$at-drawer-content-width: 460px !default;

/* FloatLayout */
$float-layout-height-min: 600px !default;
$float-layout-height-max: 950px !default;
$float-layout-header-bg-color: $color-bg-grey !default;
$float-layout-title-color: $color-text-base !default;
$float-layout-title-font-size: $font-size-lg !default;
$float-layout-btn-color: $color-grey-3 !default;

/* Grid */
$at-grid-text-color: $color-text-base !default;
$at-grid-font-size: $font-size-lg !default;
$at-grid-img-size: 80px !default;
$at-gird-img-size-sm: 50px !default;

/* ImagePicker */
$at-image-picker-btn-add-color: $color-grey-3 !default;
$at-image-picker-btn-remove-color: $color-white !default;
$at-image-picker-btn-remove-bg-color: $color-grey-2 !default;

/* Indexes */
$at-indexes-nav-color: $color-link !default;
$at-indexes-nav-font-size: $font-size-sm !default;
$at-indexes-title-color: $color-black-2 !default;
$at-indexes-title-font-size: $font-size-sm !default;
$at-indexes-title-bg-color: $color-grey-6 !default;

/* InputNumber */
$at-input-number-text-color: $color-text-base !default;
$at-input-number-font-size: $font-size-base !default;
$at-input-number-font-size-lg: $font-size-xl !default;
$at-input-number-btn-color: $color-brand !default;
$at-input-number-btn-size: 30px !default;
$at-input-number-btn-size-lg: 36px !default;
$at-input-number-width-min: 80px !default;
$at-input-number-width-min-lg: 120px !default;

/* Input */
$at-input-label-color: $color-text-base !default;
$at-input-text-color: $color-text-base !default;
$at-input-font-size: $font-size-lg !default;
$at-input-placeholder-color: $color-grey-3 !default;

/* List */
$at-list-thumb-size: 56px !default;
$at-list-arrow-color: $color-grey-3 !default;
$at-list-text-color: $color-text-base !default;
$at-list-content-color: $color-grey-2 !default;
$at-list-extra-color: $color-grey-2 !default;
$at-list-extra-width: 235px !default;

/* LoadMore */
$at-load-more-height: 80PX !default;
$at-load-more-tips-color: $color-grey-1 !default;
$at-load-more-tips-size: $font-size-lg !default;

/* Loading */
$at-loading-size: 36px !default;
$at-loading-color: $color-brand !default;

/* Message */
$at-message-color: $color-white !default;
$at-message-font-size: $font-size-base !default;
$at-message-bg-color: $color-info !default;

/* Modal */
$at-modal-width: 540px !default;
$at-modal-header-text-color: $color-text-base !default;
$at-modal-content-text-color: $color-text-base !default;
$at-modal-btn-default-color: $color-text-base !default;
$at-modal-btn-confirm-color: $color-brand !default;
$at-modal-bg-color: $color-white !default;

/* NavBar */
$at-nav-bar-title-color: $color-text-base !default;
$at-nav-bar-link-color: $color-brand !default;

/* NoticeBar */
$at-noticebar-text-color: #DE8C17 !default;
$at-noticebar-bg-color: #FCF6ED !default;
$at-noticebar-font-size: $font-size-sm !default;
$at-noticebar-icon-size: 30px !default;
$at-noticebar-btn-close-size: 32px !default;
$at-noticebar-btn-close-color: $color-grey-3 !default;

/* Pagination */
$at-pagination-margin: 40px !default;
$at-pagination-num-color: $color-text-base !default;
$at-pagination-num-font-size: $font-size-base !default;
$at-pagination-current-num-color: $color-brand !default;
$at-pagination-icon-color: $color-text-base !default;
$at-pagination-icon-font-size: 32px !default;

/* Progress */
$at-progress-height: 16px !default;
$at-progress-text-size: $font-size-sm !default;
$at-progress-icon-size: $font-size-xl !default;
$at-progress-inner-bg-color: $color-grey-6 !default;
$at-progress-bar-bg-color: $color-brand-light !default;
$at-progress-bar-bg-color-active: $color-white !default;

/* Radio */
$at-radio-title-color: $color-text-base !default;
$at-radio-title-size: $font-size-lg !default;
$at-radio-desc-color: $color-grey-2 !default;
$at-radio-desc-size: $font-size-sm !default;
$at-radio-check-color: $color-brand !default;

/* Range */
$at-range-slider-size: 28PX !default;
$at-range-rail-height: 2PX !default;
$at-range-rail-bg-color: #E9E9E9 !default;
$at-range-track-bg-color: $color-brand !default;
$at-range-slider-color: $color-white !default;
$at-range-slider-shadow: 0 0 4PX 0 rgba(0, 0, 0, 0.2) !default;

/* Rate */
$at-rate-icon-size: 20PX !default;
$at-rate-star-color: #ECECEC !default;
$at-rate-star-color-on: #FFCA3E !default;

/* SearchBar */
$at-search-bar-btn-color: $color-white !default;
$at-search-bar-btn-bg-color: $color-brand !default;

/* SegmentedControl */
$at-segmented-control-color: $color-brand !default;
$at-segmented-control-color-active: $color-white !default;
$at-segmented-control-bg-color: transparent !default;
$at-segmented-control-font-size: $font-size-base !default;

/* Slider */
$at-slider-text-color: $color-grey-2 !default;
$at-slider-text-size: $font-size-base !default;

/* Steps */
$at-steps-circle-size: 56px !default;
$at-steps-icon-size: $font-size-sm !default;
$at-steps-color: $color-white !default;
$at-steps-color-active: $color-grey-2 !default;
$at-steps-bg-color: $color-grey-4 !default;
$at-steps-bg-color-active: $color-brand !default;
$at-steps-line-color: $color-grey-3 !default;
$at-steps-title-color: $color-black-0 !default;
$at-steps-title-size: $font-size-lg !default;
$at-steps-desc-color: $color-grey-3 !default;
$at-steps-desc-size: $font-size-sm !default;

/* SwipeAction */
$at-swipe-action-color: $color-white !default;
$at-swipe-action-font-size: $font-size-base !default;
$at-swipe-action-bg-color: $color-white !default;
$at-swipe-action-option-bg-color: $color-grey-2 !default;

/* Switch */
$at-switch-title-color: $color-text-base !default;
$at-switch-title-size: $font-size-base !default;

/* TabBar */
$at-tab-bar-bg-color: $color-white !default;
$at-tab-bar-color: $color-text-base !default;
$at-tab-bar-color-active: $color-brand !default;
$at-tab-bar-font-size: $font-size-base !default;
$at-tab-bar-icon-color: $color-grey-0 !default;
$at-tab-bar-icon-font-size: 48px !default;
$at-tab-bar-icon-image-size: 50px !default;

/* Tabs */
$at-tabs-color: $color-text-base !default;
$at-tabs-color-active: $color-brand !default;
$at-tabs-font-size: $font-size-base !default;
$at-tabs-line-height: 1PX !default;
$at-tabs-underline-color: $color-grey-5 !default;
$at-tabs-bg-color: $color-bg !default;

/* Tag */
$at-tag-height: 60px !default;
$at-tag-height-sm: 40px !default;
$at-tag-color: $color-grey-1 !default;
$at-tag-color-primary: $color-grey-1 !default;
$at-tag-color-active: $color-brand-light !default;
$at-tag-color-primary-active: $color-text-base-inverse !default;
$at-tag-font-size: $font-size-base !default;
$at-tag-font-size-sm: $font-size-xs !default;
$at-tag-bg-color: $color-bg-grey !default;
$at-tag-bg-color-primary: $color-bg-grey !default;
$at-tag-bg-color-active: $color-white !default;
$at-tag-bg-color-primary-active: $at-tag-color-active !default;
$at-tag-border-color: $at-tag-bg-color !default;
$at-tag-border-color-primary: $at-tag-bg-color !default;
$at-tag-border-color-active: $at-tag-color-active !default;

/* Textarea */
$at-textarea-font-size: $font-size-lg !default;
$at-textarea-tips-color: $color-text-placeholder !default;
$at-textarea-tips-size: $font-size-base !default;

/* Timeline */
$at-timeline-offset-left: 40px !default;
$at-timeline-title-color: $color-grey-0 !default;
$at-timeline-title-font-size: $font-size-base !default;
$at-timeline-desc-color: $color-grey-1 !default;
$at-timeline-desc-font-size: $font-size-sm !default;
$at-timeline-dot-size: 24px !default;
$at-timeline-dot-color: $color-bg !default;
$at-timeline-dot-border-color: $color-brand-light !default;
$at-timeline-line-color: $color-border-lighter !default;

/**
 * Mixins
 */

/* library */
/**
 * 元素居中定位
 */
@mixin absolute-center($pos: absolute) {
  position: $pos;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/**
 * @example scss
 *
 * .element {
 *   @include clearfix;
 * }
 *
 * // CSS Output
 * .element::after {
 *   clear: both;
 *   content: '';
 *   display: block;
 * }
 */
@mixin clearfix {
  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

@mixin line($num: 1) {
  overflow: hidden;
  text-overflow: ellipsis;

  @if ($num == 1) {
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $num;

    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }
}

/**
 * 通用的遮罩
 */
@mixin overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba($color: #000, $alpha: 0.3);
}

/**
 * Mixes a color with black. It's different from darken()
 *
 * @param {color} $color
 * @param {number (percentage)} $percent [The amount of black to be mixed in]
 * @return {color}
 *
 * @example
 *   .element {
 *     background-color: shade(#ffbb52, 60%);
 *   }
 *
 *   // CSS Output
 *   .element {
 *     background-color: #664a20;
 *   }
 */
@function shade(
  $color,
  $percent
) {
  @return mix(#000, $color, $percent);
}

/**
 * Mixes a color with white. It's different from lighten()
 *
 * @param {color} $color
 * @param {number (percentage)} $percent [The amout of white to be mixed in]
 * @return {color}
 *
 * @example
 *   .element {
 *     background-color: tint(#6ecaa6 , 40%);
 *   }
 *
 *   // CSS Output
 *   .element {
 *     background-color: #a8dfc9;
 *   }
 */
@function tint(
  $color,
  $percent
) {
  @return mix(#FFF, $color, $percent);
}

@mixin display-flex {
  display: flex;
}

@mixin flex-wrap($value: nowrap) {
  flex-wrap: $value;
}

@mixin align-items($value: stretch) {
  align-items: $value;
  @if $value == flex-start {
    -webkit-box-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
  } @else {
    -webkit-box-align: $value;
  }
}

@mixin align-content($value: flex-start) {
  align-content: $value;
}

@mixin justify-content($value: flex-start) {
  justify-content: $value;
  @if $value == flex-start {
    -webkit-box-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
  } @else {
    -webkit-box-pack: $value;
  }
}

/* Flex Item */
@mixin flex($fg: 1, $fs: null, $fb: null) {
  flex: $fg $fs $fb;
  -webkit-box-flex: $fg;
}

@mixin flex-order($n) {
  order: $n;
  -webkit-box-ordinal-group: $n;
}

@mixin align-self($value: auto) {
  align-self: $value;
}

/**
 * 默认主题下 $color-border-light
 */

@mixin border-thin(
  $color: $color-border-light,
  $style: solid,
  $directions: top bottom right left,
  $width: 1px
) {
  @each $value in $directions {
    border-#{$value}: $width $color $style;
  }
}

@mixin border-thin-top(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, top, $width);
}

@mixin border-thin-left(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, left, $width);
}

@mixin border-thin-right(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, right, $width);
}

@mixin border-thin-bottom(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, bottom, $width);
}

/**
 * 点击态
 */
@mixin active {
  transition: background-color 0.3s;

  &:active {
    background-color: $color-grey-5;
  }
}

/**
 * 禁止态
 */
@mixin disabled {
  color: #bbb;
}

/**
 * 设置placeholder 颜色
 */
@mixin placeholder($color: $color-grey-3) {
  @at-root .placeholder {
    color: $color;
  }

  &::placeholder {
    color: $color;
  }
}

// 修复小元素文本垂直居中
@mixin alignhack($position: before, $margintop: 1px) {
  &::#{$position} {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    margin-top: $margintop;
  }
}

/**
 * 默认主题下 $color-border-light
 */

@mixin hairline-common() {
  content: '';
  position: absolute;
  transform-origin: center;
  box-sizing: border-box;
  pointer-events: none;
}

@mixin hairline-base(
  $color: $color-border-light,
  $style: solid
) {
  @include hairline-common();

  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  border: 0 $style $color;
  transform: scale(0.5);
}

@mixin hairline-surround(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-width: $width;
  }
}

@mixin hairline-top(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-top-width: $width;
  }
}

@mixin hairline-bottom(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-bottom-width: $width;
  }
}

@mixin hairline-left(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-left-width: $width;
  }
}

@mixin hairline-right(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-right-width: $width;
  }
}

@mixin hairline-top-bottom(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-top-width: $width;
    border-bottom-width: $width;
  }
}

@mixin hairline-bottom-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $left: 0
) {
  position: relative;

  &::after {
    @include hairline-common();

    top: auto;
    left: $left;
    right: 0;
    bottom: 0;
    transform: scaleY(0.5);
    border-bottom: $width $style $color;
  }
}

@mixin hairline-top-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $left: 0
) {
  position: relative;

  &::before {
    @include hairline-common();

    top: 0;
    left: $left;
    right: 0;
    bottom: auto;
    transform: scaleY(0.5);
    border-top: $width $style $color;
  }
}

@mixin hairline-left-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $top: 0
) {
  position: relative;

  &::after {
    @include hairline-common();

    top: $top;
    left: 0;
    right: auto;
    bottom: 0;
    transform: scaleX(0.5);
    border-left: $width $style $color;
  }
}

@mixin hairline-right-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $top: 0
) {
  position: relative;

  &::after {
    @include hairline-common();

    top: $top;
    left: auto;
    right: 0;
    bottom: 0;
    transform: scaleX(0.5);
    border-right: $width $style $color;
  }
}



$component: '.at-switch';

#{$component} {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-bg;
  margin-left: $spacing-h-xl;
  padding: $spacing-v-lg $spacing-h-xl $spacing-v-lg 0;
  margin-bottom: 1PX;
  @include hairline-bottom();

  /* elements */
  &__title {
    flex: 6;
    color: $at-switch-title-color;
    font-size: $at-switch-title-size;
  }

  &__container {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    position: relative;
    flex: 2;
    background-color: $color-bg;
  }

  &__switch {
    display: inline-block;
  }

  &__mask {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zindex-form;
  }

  /* modifiers */
  &--disabled {
    #{$component}__switch {
      opacity: $opacity-disabled;
    }

    #{$component}__mask {
      display: block;
    }
  }

  &--without-border {
    &::after {
      border: none;
    }
  }
}
/**
 * Default variables
 */

/**
 * Mixes a color with white. It's different from lighten()
 *
 * @param {color} $color
 * @param {number (percentage)} $percent [The amout of white to be mixed in]
 * @return {color}
 *
 * @example
 *   .element {
 *     background-color: tint(#6ecaa6 , 40%);
 *   }
 *
 *   // CSS Output
 *   .element {
 *     background-color: #a8dfc9;
 *   }
 */
@function tint(
  $color,
  $percent
) {
  @return mix(#FFF, $color, $percent);
}


$hd: 2 !default; // 基本单位

/* The Color of O2Team Brand */
$color-brand: #6190E8 !default;
$color-brand-light: #78A4F4 !default;
$color-brand-dark: #346FC2 !default;

/* Color */
$color-success: #13CE66 !default;
$color-error: #FF4949 !default;
$color-warning: #FFC82C !default;
$color-info: #78A4FA !default;

/* Color Palette */
$color-black-0: #000 !default;
$color-black-1: #333 !default;
$color-black-2: #7F7F7F !default;
$color-black-3: #B2B2B2 !default;

$color-grey-0: #333 !default;
$color-grey-1: #666 !default;
$color-grey-2: #999 !default;
$color-grey-3: #CCC !default;
$color-grey-4: #E5E5E5 !default;
$color-grey-5: #F0F0F0 !default;
$color-grey-6: #F7F7F7 !default;

$color-white: #FFF !default;

/* Text Color */
$color-text-base: #333 !default; // 文字的基本色
$color-text-base-inverse: #FFF !default; // 反色
$color-text-secondary: #36D57D !default; // 辅助色
$color-text-placeholder: #C9C9C9 !default;
$color-text-disabled: #CCC !default;
$color-text-title: #2C405A !default; // 文章标题
$color-text-paragraph: #3F536E !default; // 文章段落

/* Link */
$color-link: #6190E8 !default;
$color-link-hover: #79A1EB !default;
$color-link-active: #4F7DE2 !default;
$color-link-disabled: #BFBFBF !default;

/* 背景色 */
$color-bg: #FFF !default;
$color-bg-base: #FAFBFC !default;
$color-bg-light: #ECF5FD !default;
$color-bg-lighter: tint($color-bg-light, 50%) !default;
$color-bg-grey: #F7F7F7 !default;

/* 边框颜色 */
$color-border-base: #C5D9E8 !default;
$color-border-split: tint($color-border-base, 20%) !default; // 分割线
$color-border-light: tint($color-border-base, 30%) !default;
$color-border-lighter: tint($color-border-base, 50%) !default;
$color-border-lightest: tint($color-border-base, 80%) !default;
$color-border-grey: #CCC !default;

/* 图标颜色 */
$color-icon-base: #CCC !default;

/* Border Radius */
$border-radius-sm: 2px * $hd !default;
$border-radius-md: 4px * $hd !default;
$border-radius-lg: 6px * $hd !default;
$border-radius-circle: 50% !default;

/* 透明度 */
$opacity-active: 0.6 !default; // Button 等组件点击态额透明度
$opacity-disabled: 0.3 !default; // Button 等组件禁用态的透明度

/* Font */
$font-size-xs: 10px * $hd !default; // 非常用字号，用于标签
$font-size-sm: 12px * $hd !default; // 用于辅助信息
$font-size-base: 14px * $hd !default; // 常用字号
$font-size-lg: 16px * $hd !default; // 常规标题
$font-size-xl: 18px * $hd !default; // 大标题
$font-size-xxl: 20px * $hd !default; // 用于大号的数字

/* Line Height */
$line-height-base: 1 !default; // 单行
$line-height-en: 1.3 !default; // 英文多行
$line-height-zh: 1.5 !default; // 中文多行

/* 水平间距 */
$spacing-h-sm: 5px * $hd !default;
$spacing-h-md: 8px * $hd !default;
$spacing-h-lg: 12px * $hd !default;
$spacing-h-xl: 16px * $hd !default;

/* 垂直间距 */
$spacing-v-xs: 3px * $hd !default;
$spacing-v-sm: 6px * $hd !default;
$spacing-v-md: 9px * $hd !default;
$spacing-v-lg: 12px * $hd !default;
$spacing-v-xl: 15px * $hd !default;

/* 图标尺寸 */
$icon-size-sm: 18px * $hd !default;
$icon-size-md: 22px * $hd !default;
$icon-size-lg: 36px * $hd !default;

/* z-index */
$zindex-divider: 100 !default;
$zindex-steps: 500 !default;
$zindex-tab: 600 !default;
$zindex-form: 700 !default;
$zindex-nav: 800 !default;
$zindex-search-bar: 800 !default;
$zindex-indexes: 805 !default;
$zindex-flot-layout: 810 !default;
$zindex-drawer: 900 !default;
$zindex-modal: 1000 !default;
$zindex-action-sheet: 1010 !default;
$zindex-picker: 1010 !default;
$zindex-curtain: 1080 !default;
$zindex-message: 1090 !default;
$zindex-toast: 1090 !default;

/* timing function */
$timing-func: cubic-bezier(0.36, 0.66, 0.04, 1) !default;

/**
* CSS cubic-bezier timing functions
* http://bourbon.io/docs/#timing-functions
*/
$ease-in-quad: cubic-bezier(0.550, 0.085, 0.680, 0.530) !default;
$ease-in-cubic: cubic-bezier(0.550, 0.055, 0.675, 0.190) !default;
$ease-in-quart: cubic-bezier(0.895, 0.030, 0.685, 0.220) !default;
$ease-in-quint: cubic-bezier(0.755, 0.050, 0.855, 0.060) !default;
$ease-in-sine: cubic-bezier(0.470, 0.000, 0.745, 0.715) !default;
$ease-in-expo: cubic-bezier(0.950, 0.050, 0.795, 0.035) !default;
$ease-in-circ: cubic-bezier(0.600, 0.040, 0.980, 0.335) !default;
$ease-in-back: cubic-bezier(0.600, -0.280, 0.735, 0.045) !default;

$ease-out-quad: cubic-bezier(0.250, 0.460, 0.450, 0.940) !default;
$ease-out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000) !default;
$ease-out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000) !default;
$ease-out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000) !default;
$ease-out-sine: cubic-bezier(0.390, 0.575, 0.565, 1.000) !default;
$ease-out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000) !default;
$ease-out-circ: cubic-bezier(0.075, 0.820, 0.165, 1.000) !default;
$ease-out-back: cubic-bezier(0.175, 0.885, 0.320, 1.275) !default;

$ease-in-out-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955) !default;
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000) !default;
$ease-in-out-quart: cubic-bezier(0.770, 0.000, 0.175, 1.000) !default;
$ease-in-out-quint: cubic-bezier(0.860, 0.000, 0.070, 1.000) !default;
$ease-in-out-sine: cubic-bezier(0.445, 0.050, 0.550, 0.950) !default;
$ease-in-out-expo: cubic-bezier(1.000, 0.000, 0.000, 1.000) !default;
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.150, 0.860) !default;
$ease-in-out-back: cubic-bezier(0.680, -0.550, 0.265, 1.550) !default;

/**
 * 组件变量
 */

/* Accordion */
$at-accordion-color-arrow: $color-grey-3 !default;

/* Activity Indicator */
$at-activity-indicator-font-size: 28px !default;
$at-activity-indicator-font-color: $color-grey-2 !default;

/* Avatar */
$at-avatar-color: $color-white !default;
$at-avatar-bg-color: $color-grey-4 !default;
$at-avatar-size-sm: 80px !default;
$at-avatar-size-md: 100px !default;
$at-avatar-size-lg: 120px !default;

/* Badge */
$at-badge-color: $color-white !default;
$at-badge-bg-color: $color-error !default;
$at-badge-bg: $at-badge-bg-color !default;
$at-badge-font-size: $font-size-xs !default;
$at-badge-dot-size: 20px !default;

/* Button */
$at-button-height: 92px !default;
$at-button-height-sm: 60px !default;
$at-button-color: $color-brand !default;
$at-button-border-color-primary: $color-brand !default;
$at-button-border-color-secondary: $color-brand !default;
$at-button-bg: $at-button-color !default;

/* Float Button */
$at-fab-size: 56px * $hd !default;
$at-fab-size-sm: 40px * $hd !default;
$at-fab-icon-size: 24px * $hd !default;
$at-fab-bg-color: $color-brand;
$at-fab-bg-color-active: $color-brand-dark;
$at-fab-box-shadow:
  0 6px 10px -2px rgba(0, 0, 0, 0.2),
  0 12px 20px 0 rgba(0, 0, 0, 0.14),
  0 2px 36px 0 rgba(0, 0, 0, 0.12) !default;
$at-fab-box-shadow-active:
  0 14px 16px -8px rgba(0, 0, 0, 0.2),
  0 24px 34px 4px rgba(0, 0, 0, 0.14),
  0 10px 44px 8px rgba(0, 0, 0, 0.12) !default;

/* Calendar */
$at-calendar-day-size: 72px !default;
$at-calendar-mark-size: 8px !default;
$at-calendar-header-color: #B8BFC6 !default;
$at-calendar-main-color: $color-brand !default;
$at-calendar-day-color: #7C86A2 !default;

/* Card */
$at-card-thumb-size: 32px !default;
$at-card-icon-size: 32px !default;
$at-card-title-color: $color-text-title !default;
$at-card-extra-color: $color-text-title !default;
$at-card-info-color: $color-text-base !default;
$at-card-note-color: $color-grey-2 !default;

/* Checkbox */
$at-checkbox-circle-size: 40px !default;
$at-checkbox-icon-size: $font-size-sm !default;
$at-checkbox-icon-color: $color-brand !default;
$at-checkbox-icon-color-checked: $color-white !default;
$at-checkbox-title-color: $color-text-base !default;
$at-checkbox-title-font-size: $font-size-lg !default;
$at-checkbox-desc-font-size: $font-size-sm !default;
$at-checkbox-desc-color: $color-grey-2 !default;

/* Countdown */
$at-countdown-font-size: $font-size-lg !default;
$at-countdown-num-color: $color-text-base !default;
$at-countdown-card-num-color: #FF4949 !default;
$at-countdown-card-num-bg-color: $color-white !default;

/* Curtain */
$at-curtain-btn-color: $color-white !default;

/* Divider */
$at-divider-height: 112px;
$at-divider-content-color: $color-brand !default;
$at-divider-font-size: $font-size-lg !default;
$at-divider-line-color: $color-grey-3 !default;

/* Drawer */
$at-drawer-content-width: 460px !default;

/* FloatLayout */
$float-layout-height-min: 600px !default;
$float-layout-height-max: 950px !default;
$float-layout-header-bg-color: $color-bg-grey !default;
$float-layout-title-color: $color-text-base !default;
$float-layout-title-font-size: $font-size-lg !default;
$float-layout-btn-color: $color-grey-3 !default;

/* Grid */
$at-grid-text-color: $color-text-base !default;
$at-grid-font-size: $font-size-lg !default;
$at-grid-img-size: 80px !default;
$at-gird-img-size-sm: 50px !default;

/* ImagePicker */
$at-image-picker-btn-add-color: $color-grey-3 !default;
$at-image-picker-btn-remove-color: $color-white !default;
$at-image-picker-btn-remove-bg-color: $color-grey-2 !default;

/* Indexes */
$at-indexes-nav-color: $color-link !default;
$at-indexes-nav-font-size: $font-size-sm !default;
$at-indexes-title-color: $color-black-2 !default;
$at-indexes-title-font-size: $font-size-sm !default;
$at-indexes-title-bg-color: $color-grey-6 !default;

/* InputNumber */
$at-input-number-text-color: $color-text-base !default;
$at-input-number-font-size: $font-size-base !default;
$at-input-number-font-size-lg: $font-size-xl !default;
$at-input-number-btn-color: $color-brand !default;
$at-input-number-btn-size: 30px !default;
$at-input-number-btn-size-lg: 36px !default;
$at-input-number-width-min: 80px !default;
$at-input-number-width-min-lg: 120px !default;

/* Input */
$at-input-label-color: $color-text-base !default;
$at-input-text-color: $color-text-base !default;
$at-input-font-size: $font-size-lg !default;
$at-input-placeholder-color: $color-grey-3 !default;

/* List */
$at-list-thumb-size: 56px !default;
$at-list-arrow-color: $color-grey-3 !default;
$at-list-text-color: $color-text-base !default;
$at-list-content-color: $color-grey-2 !default;
$at-list-extra-color: $color-grey-2 !default;
$at-list-extra-width: 235px !default;

/* LoadMore */
$at-load-more-height: 80PX !default;
$at-load-more-tips-color: $color-grey-1 !default;
$at-load-more-tips-size: $font-size-lg !default;

/* Loading */
$at-loading-size: 36px !default;
$at-loading-color: $color-brand !default;

/* Message */
$at-message-color: $color-white !default;
$at-message-font-size: $font-size-base !default;
$at-message-bg-color: $color-info !default;

/* Modal */
$at-modal-width: 540px !default;
$at-modal-header-text-color: $color-text-base !default;
$at-modal-content-text-color: $color-text-base !default;
$at-modal-btn-default-color: $color-text-base !default;
$at-modal-btn-confirm-color: $color-brand !default;
$at-modal-bg-color: $color-white !default;

/* NavBar */
$at-nav-bar-title-color: $color-text-base !default;
$at-nav-bar-link-color: $color-brand !default;

/* NoticeBar */
$at-noticebar-text-color: #DE8C17 !default;
$at-noticebar-bg-color: #FCF6ED !default;
$at-noticebar-font-size: $font-size-sm !default;
$at-noticebar-icon-size: 30px !default;
$at-noticebar-btn-close-size: 32px !default;
$at-noticebar-btn-close-color: $color-grey-3 !default;

/* Pagination */
$at-pagination-margin: 40px !default;
$at-pagination-num-color: $color-text-base !default;
$at-pagination-num-font-size: $font-size-base !default;
$at-pagination-current-num-color: $color-brand !default;
$at-pagination-icon-color: $color-text-base !default;
$at-pagination-icon-font-size: 32px !default;

/* Progress */
$at-progress-height: 16px !default;
$at-progress-text-size: $font-size-sm !default;
$at-progress-icon-size: $font-size-xl !default;
$at-progress-inner-bg-color: $color-grey-6 !default;
$at-progress-bar-bg-color: $color-brand-light !default;
$at-progress-bar-bg-color-active: $color-white !default;

/* Radio */
$at-radio-title-color: $color-text-base !default;
$at-radio-title-size: $font-size-lg !default;
$at-radio-desc-color: $color-grey-2 !default;
$at-radio-desc-size: $font-size-sm !default;
$at-radio-check-color: $color-brand !default;

/* Range */
$at-range-slider-size: 28PX !default;
$at-range-rail-height: 2PX !default;
$at-range-rail-bg-color: #E9E9E9 !default;
$at-range-track-bg-color: $color-brand !default;
$at-range-slider-color: $color-white !default;
$at-range-slider-shadow: 0 0 4PX 0 rgba(0, 0, 0, 0.2) !default;

/* Rate */
$at-rate-icon-size: 20PX !default;
$at-rate-star-color: #ECECEC !default;
$at-rate-star-color-on: #FFCA3E !default;

/* SearchBar */
$at-search-bar-btn-color: $color-white !default;
$at-search-bar-btn-bg-color: $color-brand !default;

/* SegmentedControl */
$at-segmented-control-color: $color-brand !default;
$at-segmented-control-color-active: $color-white !default;
$at-segmented-control-bg-color: transparent !default;
$at-segmented-control-font-size: $font-size-base !default;

/* Slider */
$at-slider-text-color: $color-grey-2 !default;
$at-slider-text-size: $font-size-base !default;

/* Steps */
$at-steps-circle-size: 56px !default;
$at-steps-icon-size: $font-size-sm !default;
$at-steps-color: $color-white !default;
$at-steps-color-active: $color-grey-2 !default;
$at-steps-bg-color: $color-grey-4 !default;
$at-steps-bg-color-active: $color-brand !default;
$at-steps-line-color: $color-grey-3 !default;
$at-steps-title-color: $color-black-0 !default;
$at-steps-title-size: $font-size-lg !default;
$at-steps-desc-color: $color-grey-3 !default;
$at-steps-desc-size: $font-size-sm !default;

/* SwipeAction */
$at-swipe-action-color: $color-white !default;
$at-swipe-action-font-size: $font-size-base !default;
$at-swipe-action-bg-color: $color-white !default;
$at-swipe-action-option-bg-color: $color-grey-2 !default;

/* Switch */
$at-switch-title-color: $color-text-base !default;
$at-switch-title-size: $font-size-base !default;

/* TabBar */
$at-tab-bar-bg-color: $color-white !default;
$at-tab-bar-color: $color-text-base !default;
$at-tab-bar-color-active: $color-brand !default;
$at-tab-bar-font-size: $font-size-base !default;
$at-tab-bar-icon-color: $color-grey-0 !default;
$at-tab-bar-icon-font-size: 48px !default;
$at-tab-bar-icon-image-size: 50px !default;

/* Tabs */
$at-tabs-color: $color-text-base !default;
$at-tabs-color-active: $color-brand !default;
$at-tabs-font-size: $font-size-base !default;
$at-tabs-line-height: 1PX !default;
$at-tabs-underline-color: $color-grey-5 !default;
$at-tabs-bg-color: $color-bg !default;

/* Tag */
$at-tag-height: 60px !default;
$at-tag-height-sm: 40px !default;
$at-tag-color: $color-grey-1 !default;
$at-tag-color-primary: $color-grey-1 !default;
$at-tag-color-active: $color-brand-light !default;
$at-tag-color-primary-active: $color-text-base-inverse !default;
$at-tag-font-size: $font-size-base !default;
$at-tag-font-size-sm: $font-size-xs !default;
$at-tag-bg-color: $color-bg-grey !default;
$at-tag-bg-color-primary: $color-bg-grey !default;
$at-tag-bg-color-active: $color-white !default;
$at-tag-bg-color-primary-active: $at-tag-color-active !default;
$at-tag-border-color: $at-tag-bg-color !default;
$at-tag-border-color-primary: $at-tag-bg-color !default;
$at-tag-border-color-active: $at-tag-color-active !default;

/* Textarea */
$at-textarea-font-size: $font-size-lg !default;
$at-textarea-tips-color: $color-text-placeholder !default;
$at-textarea-tips-size: $font-size-base !default;

/* Timeline */
$at-timeline-offset-left: 40px !default;
$at-timeline-title-color: $color-grey-0 !default;
$at-timeline-title-font-size: $font-size-base !default;
$at-timeline-desc-color: $color-grey-1 !default;
$at-timeline-desc-font-size: $font-size-sm !default;
$at-timeline-dot-size: 24px !default;
$at-timeline-dot-color: $color-bg !default;
$at-timeline-dot-border-color: $color-brand-light !default;
$at-timeline-line-color: $color-border-lighter !default;

/**
 * Mixins
 */

/* library */
/**
 * 元素居中定位
 */
@mixin absolute-center($pos: absolute) {
  position: $pos;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/**
 * @example scss
 *
 * .element {
 *   @include clearfix;
 * }
 *
 * // CSS Output
 * .element::after {
 *   clear: both;
 *   content: '';
 *   display: block;
 * }
 */
@mixin clearfix {
  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

@mixin line($num: 1) {
  overflow: hidden;
  text-overflow: ellipsis;

  @if ($num == 1) {
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $num;

    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }
}

/**
 * 通用的遮罩
 */
@mixin overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba($color: #000, $alpha: 0.3);
}

/**
 * Mixes a color with black. It's different from darken()
 *
 * @param {color} $color
 * @param {number (percentage)} $percent [The amount of black to be mixed in]
 * @return {color}
 *
 * @example
 *   .element {
 *     background-color: shade(#ffbb52, 60%);
 *   }
 *
 *   // CSS Output
 *   .element {
 *     background-color: #664a20;
 *   }
 */
@function shade(
  $color,
  $percent
) {
  @return mix(#000, $color, $percent);
}

/**
 * Mixes a color with white. It's different from lighten()
 *
 * @param {color} $color
 * @param {number (percentage)} $percent [The amout of white to be mixed in]
 * @return {color}
 *
 * @example
 *   .element {
 *     background-color: tint(#6ecaa6 , 40%);
 *   }
 *
 *   // CSS Output
 *   .element {
 *     background-color: #a8dfc9;
 *   }
 */
@function tint(
  $color,
  $percent
) {
  @return mix(#FFF, $color, $percent);
}

@mixin display-flex {
  display: flex;
}

@mixin flex-wrap($value: nowrap) {
  flex-wrap: $value;
}

@mixin align-items($value: stretch) {
  align-items: $value;
  @if $value == flex-start {
    -webkit-box-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
  } @else {
    -webkit-box-align: $value;
  }
}

@mixin align-content($value: flex-start) {
  align-content: $value;
}

@mixin justify-content($value: flex-start) {
  justify-content: $value;
  @if $value == flex-start {
    -webkit-box-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
  } @else {
    -webkit-box-pack: $value;
  }
}

/* Flex Item */
@mixin flex($fg: 1, $fs: null, $fb: null) {
  flex: $fg $fs $fb;
  -webkit-box-flex: $fg;
}

@mixin flex-order($n) {
  order: $n;
  -webkit-box-ordinal-group: $n;
}

@mixin align-self($value: auto) {
  align-self: $value;
}

/**
 * 默认主题下 $color-border-light
 */

@mixin border-thin(
  $color: $color-border-light,
  $style: solid,
  $directions: top bottom right left,
  $width: 1px
) {
  @each $value in $directions {
    border-#{$value}: $width $color $style;
  }
}

@mixin border-thin-top(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, top, $width);
}

@mixin border-thin-left(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, left, $width);
}

@mixin border-thin-right(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, right, $width);
}

@mixin border-thin-bottom(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, bottom, $width);
}

/**
 * 点击态
 */
@mixin active {
  transition: background-color 0.3s;

  &:active {
    background-color: $color-grey-5;
  }
}

/**
 * 禁止态
 */
@mixin disabled {
  color: #bbb;
}

/**
 * 设置placeholder 颜色
 */
@mixin placeholder($color: $color-grey-3) {
  @at-root .placeholder {
    color: $color;
  }

  &::placeholder {
    color: $color;
  }
}

// 修复小元素文本垂直居中
@mixin alignhack($position: before, $margintop: 1px) {
  &::#{$position} {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    margin-top: $margintop;
  }
}

/**
 * 默认主题下 $color-border-light
 */

@mixin hairline-common() {
  content: '';
  position: absolute;
  transform-origin: center;
  box-sizing: border-box;
  pointer-events: none;
}

@mixin hairline-base(
  $color: $color-border-light,
  $style: solid
) {
  @include hairline-common();

  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  border: 0 $style $color;
  transform: scale(0.5);
}

@mixin hairline-surround(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-width: $width;
  }
}

@mixin hairline-top(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-top-width: $width;
  }
}

@mixin hairline-bottom(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-bottom-width: $width;
  }
}

@mixin hairline-left(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-left-width: $width;
  }
}

@mixin hairline-right(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-right-width: $width;
  }
}

@mixin hairline-top-bottom(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-top-width: $width;
    border-bottom-width: $width;
  }
}

@mixin hairline-bottom-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $left: 0
) {
  position: relative;

  &::after {
    @include hairline-common();

    top: auto;
    left: $left;
    right: 0;
    bottom: 0;
    transform: scaleY(0.5);
    border-bottom: $width $style $color;
  }
}

@mixin hairline-top-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $left: 0
) {
  position: relative;

  &::before {
    @include hairline-common();

    top: 0;
    left: $left;
    right: 0;
    bottom: auto;
    transform: scaleY(0.5);
    border-top: $width $style $color;
  }
}

@mixin hairline-left-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $top: 0
) {
  position: relative;

  &::after {
    @include hairline-common();

    top: $top;
    left: 0;
    right: auto;
    bottom: 0;
    transform: scaleX(0.5);
    border-left: $width $style $color;
  }
}

@mixin hairline-right-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $top: 0
) {
  position: relative;

  &::after {
    @include hairline-common();

    top: $top;
    left: auto;
    right: 0;
    bottom: 0;
    transform: scaleX(0.5);
    border-right: $width $style $color;
  }
}



$at-tab-bar-min-height: 50px;
$at-tab-bar-title-max-width: 200px;

.at-tab-bar {
  display: flex;
  padding: $spacing-v-sm 0 $spacing-v-xs;
  width: 100%;
  min-height: $at-tab-bar-min-height;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  box-sizing: border-box;
  background-color: $at-tab-bar-bg-color;
  @include hairline-top();

  /* elements */
  &__item {
    flex: 1;
    padding: $spacing-v-sm $spacing-v-sm;
    color: $at-tab-bar-color;
    font-size: 0;
    transition: all 0.2s;

    &--active {
      color: $at-tab-bar-color-active;
    }
  }

  &__icon {
    font-size: $at-tab-bar-icon-font-size;
    line-height: 1;
  }

  &__inner-img {
    width: $at-tab-bar-icon-image-size;
    height: $at-tab-bar-icon-image-size;

    &--inactive {
      display: none;
    }
  }

  &__title {
    display: block;
    padding: $spacing-v-xs 0;
    max-width: $at-tab-bar-title-max-width;
    font-size: $at-tab-bar-font-size;
    line-height: $line-height-base;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* modifiers */
  &--fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    z-index: $zindex-nav;
  }

  &--ipx {
    &.at-tab-bar--fixed {
      padding-bottom: 68px;
    }
  }
}
/**
 * Default variables
 */

/**
 * Mixes a color with white. It's different from lighten()
 *
 * @param {color} $color
 * @param {number (percentage)} $percent [The amout of white to be mixed in]
 * @return {color}
 *
 * @example
 *   .element {
 *     background-color: tint(#6ecaa6 , 40%);
 *   }
 *
 *   // CSS Output
 *   .element {
 *     background-color: #a8dfc9;
 *   }
 */
@function tint(
  $color,
  $percent
) {
  @return mix(#FFF, $color, $percent);
}


$hd: 2 !default; // 基本单位

/* The Color of O2Team Brand */
$color-brand: #6190E8 !default;
$color-brand-light: #78A4F4 !default;
$color-brand-dark: #346FC2 !default;

/* Color */
$color-success: #13CE66 !default;
$color-error: #FF4949 !default;
$color-warning: #FFC82C !default;
$color-info: #78A4FA !default;

/* Color Palette */
$color-black-0: #000 !default;
$color-black-1: #333 !default;
$color-black-2: #7F7F7F !default;
$color-black-3: #B2B2B2 !default;

$color-grey-0: #333 !default;
$color-grey-1: #666 !default;
$color-grey-2: #999 !default;
$color-grey-3: #CCC !default;
$color-grey-4: #E5E5E5 !default;
$color-grey-5: #F0F0F0 !default;
$color-grey-6: #F7F7F7 !default;

$color-white: #FFF !default;

/* Text Color */
$color-text-base: #333 !default; // 文字的基本色
$color-text-base-inverse: #FFF !default; // 反色
$color-text-secondary: #36D57D !default; // 辅助色
$color-text-placeholder: #C9C9C9 !default;
$color-text-disabled: #CCC !default;
$color-text-title: #2C405A !default; // 文章标题
$color-text-paragraph: #3F536E !default; // 文章段落

/* Link */
$color-link: #6190E8 !default;
$color-link-hover: #79A1EB !default;
$color-link-active: #4F7DE2 !default;
$color-link-disabled: #BFBFBF !default;

/* 背景色 */
$color-bg: #FFF !default;
$color-bg-base: #FAFBFC !default;
$color-bg-light: #ECF5FD !default;
$color-bg-lighter: tint($color-bg-light, 50%) !default;
$color-bg-grey: #F7F7F7 !default;

/* 边框颜色 */
$color-border-base: #C5D9E8 !default;
$color-border-split: tint($color-border-base, 20%) !default; // 分割线
$color-border-light: tint($color-border-base, 30%) !default;
$color-border-lighter: tint($color-border-base, 50%) !default;
$color-border-lightest: tint($color-border-base, 80%) !default;
$color-border-grey: #CCC !default;

/* 图标颜色 */
$color-icon-base: #CCC !default;

/* Border Radius */
$border-radius-sm: 2px * $hd !default;
$border-radius-md: 4px * $hd !default;
$border-radius-lg: 6px * $hd !default;
$border-radius-circle: 50% !default;

/* 透明度 */
$opacity-active: 0.6 !default; // Button 等组件点击态额透明度
$opacity-disabled: 0.3 !default; // Button 等组件禁用态的透明度

/* Font */
$font-size-xs: 10px * $hd !default; // 非常用字号，用于标签
$font-size-sm: 12px * $hd !default; // 用于辅助信息
$font-size-base: 14px * $hd !default; // 常用字号
$font-size-lg: 16px * $hd !default; // 常规标题
$font-size-xl: 18px * $hd !default; // 大标题
$font-size-xxl: 20px * $hd !default; // 用于大号的数字

/* Line Height */
$line-height-base: 1 !default; // 单行
$line-height-en: 1.3 !default; // 英文多行
$line-height-zh: 1.5 !default; // 中文多行

/* 水平间距 */
$spacing-h-sm: 5px * $hd !default;
$spacing-h-md: 8px * $hd !default;
$spacing-h-lg: 12px * $hd !default;
$spacing-h-xl: 16px * $hd !default;

/* 垂直间距 */
$spacing-v-xs: 3px * $hd !default;
$spacing-v-sm: 6px * $hd !default;
$spacing-v-md: 9px * $hd !default;
$spacing-v-lg: 12px * $hd !default;
$spacing-v-xl: 15px * $hd !default;

/* 图标尺寸 */
$icon-size-sm: 18px * $hd !default;
$icon-size-md: 22px * $hd !default;
$icon-size-lg: 36px * $hd !default;

/* z-index */
$zindex-divider: 100 !default;
$zindex-steps: 500 !default;
$zindex-tab: 600 !default;
$zindex-form: 700 !default;
$zindex-nav: 800 !default;
$zindex-search-bar: 800 !default;
$zindex-indexes: 805 !default;
$zindex-flot-layout: 810 !default;
$zindex-drawer: 900 !default;
$zindex-modal: 1000 !default;
$zindex-action-sheet: 1010 !default;
$zindex-picker: 1010 !default;
$zindex-curtain: 1080 !default;
$zindex-message: 1090 !default;
$zindex-toast: 1090 !default;

/* timing function */
$timing-func: cubic-bezier(0.36, 0.66, 0.04, 1) !default;

/**
* CSS cubic-bezier timing functions
* http://bourbon.io/docs/#timing-functions
*/
$ease-in-quad: cubic-bezier(0.550, 0.085, 0.680, 0.530) !default;
$ease-in-cubic: cubic-bezier(0.550, 0.055, 0.675, 0.190) !default;
$ease-in-quart: cubic-bezier(0.895, 0.030, 0.685, 0.220) !default;
$ease-in-quint: cubic-bezier(0.755, 0.050, 0.855, 0.060) !default;
$ease-in-sine: cubic-bezier(0.470, 0.000, 0.745, 0.715) !default;
$ease-in-expo: cubic-bezier(0.950, 0.050, 0.795, 0.035) !default;
$ease-in-circ: cubic-bezier(0.600, 0.040, 0.980, 0.335) !default;
$ease-in-back: cubic-bezier(0.600, -0.280, 0.735, 0.045) !default;

$ease-out-quad: cubic-bezier(0.250, 0.460, 0.450, 0.940) !default;
$ease-out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000) !default;
$ease-out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000) !default;
$ease-out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000) !default;
$ease-out-sine: cubic-bezier(0.390, 0.575, 0.565, 1.000) !default;
$ease-out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000) !default;
$ease-out-circ: cubic-bezier(0.075, 0.820, 0.165, 1.000) !default;
$ease-out-back: cubic-bezier(0.175, 0.885, 0.320, 1.275) !default;

$ease-in-out-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955) !default;
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000) !default;
$ease-in-out-quart: cubic-bezier(0.770, 0.000, 0.175, 1.000) !default;
$ease-in-out-quint: cubic-bezier(0.860, 0.000, 0.070, 1.000) !default;
$ease-in-out-sine: cubic-bezier(0.445, 0.050, 0.550, 0.950) !default;
$ease-in-out-expo: cubic-bezier(1.000, 0.000, 0.000, 1.000) !default;
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.150, 0.860) !default;
$ease-in-out-back: cubic-bezier(0.680, -0.550, 0.265, 1.550) !default;

/**
 * 组件变量
 */

/* Accordion */
$at-accordion-color-arrow: $color-grey-3 !default;

/* Activity Indicator */
$at-activity-indicator-font-size: 28px !default;
$at-activity-indicator-font-color: $color-grey-2 !default;

/* Avatar */
$at-avatar-color: $color-white !default;
$at-avatar-bg-color: $color-grey-4 !default;
$at-avatar-size-sm: 80px !default;
$at-avatar-size-md: 100px !default;
$at-avatar-size-lg: 120px !default;

/* Badge */
$at-badge-color: $color-white !default;
$at-badge-bg-color: $color-error !default;
$at-badge-bg: $at-badge-bg-color !default;
$at-badge-font-size: $font-size-xs !default;
$at-badge-dot-size: 20px !default;

/* Button */
$at-button-height: 92px !default;
$at-button-height-sm: 60px !default;
$at-button-color: $color-brand !default;
$at-button-border-color-primary: $color-brand !default;
$at-button-border-color-secondary: $color-brand !default;
$at-button-bg: $at-button-color !default;

/* Float Button */
$at-fab-size: 56px * $hd !default;
$at-fab-size-sm: 40px * $hd !default;
$at-fab-icon-size: 24px * $hd !default;
$at-fab-bg-color: $color-brand;
$at-fab-bg-color-active: $color-brand-dark;
$at-fab-box-shadow:
  0 6px 10px -2px rgba(0, 0, 0, 0.2),
  0 12px 20px 0 rgba(0, 0, 0, 0.14),
  0 2px 36px 0 rgba(0, 0, 0, 0.12) !default;
$at-fab-box-shadow-active:
  0 14px 16px -8px rgba(0, 0, 0, 0.2),
  0 24px 34px 4px rgba(0, 0, 0, 0.14),
  0 10px 44px 8px rgba(0, 0, 0, 0.12) !default;

/* Calendar */
$at-calendar-day-size: 72px !default;
$at-calendar-mark-size: 8px !default;
$at-calendar-header-color: #B8BFC6 !default;
$at-calendar-main-color: $color-brand !default;
$at-calendar-day-color: #7C86A2 !default;

/* Card */
$at-card-thumb-size: 32px !default;
$at-card-icon-size: 32px !default;
$at-card-title-color: $color-text-title !default;
$at-card-extra-color: $color-text-title !default;
$at-card-info-color: $color-text-base !default;
$at-card-note-color: $color-grey-2 !default;

/* Checkbox */
$at-checkbox-circle-size: 40px !default;
$at-checkbox-icon-size: $font-size-sm !default;
$at-checkbox-icon-color: $color-brand !default;
$at-checkbox-icon-color-checked: $color-white !default;
$at-checkbox-title-color: $color-text-base !default;
$at-checkbox-title-font-size: $font-size-lg !default;
$at-checkbox-desc-font-size: $font-size-sm !default;
$at-checkbox-desc-color: $color-grey-2 !default;

/* Countdown */
$at-countdown-font-size: $font-size-lg !default;
$at-countdown-num-color: $color-text-base !default;
$at-countdown-card-num-color: #FF4949 !default;
$at-countdown-card-num-bg-color: $color-white !default;

/* Curtain */
$at-curtain-btn-color: $color-white !default;

/* Divider */
$at-divider-height: 112px;
$at-divider-content-color: $color-brand !default;
$at-divider-font-size: $font-size-lg !default;
$at-divider-line-color: $color-grey-3 !default;

/* Drawer */
$at-drawer-content-width: 460px !default;

/* FloatLayout */
$float-layout-height-min: 600px !default;
$float-layout-height-max: 950px !default;
$float-layout-header-bg-color: $color-bg-grey !default;
$float-layout-title-color: $color-text-base !default;
$float-layout-title-font-size: $font-size-lg !default;
$float-layout-btn-color: $color-grey-3 !default;

/* Grid */
$at-grid-text-color: $color-text-base !default;
$at-grid-font-size: $font-size-lg !default;
$at-grid-img-size: 80px !default;
$at-gird-img-size-sm: 50px !default;

/* ImagePicker */
$at-image-picker-btn-add-color: $color-grey-3 !default;
$at-image-picker-btn-remove-color: $color-white !default;
$at-image-picker-btn-remove-bg-color: $color-grey-2 !default;

/* Indexes */
$at-indexes-nav-color: $color-link !default;
$at-indexes-nav-font-size: $font-size-sm !default;
$at-indexes-title-color: $color-black-2 !default;
$at-indexes-title-font-size: $font-size-sm !default;
$at-indexes-title-bg-color: $color-grey-6 !default;

/* InputNumber */
$at-input-number-text-color: $color-text-base !default;
$at-input-number-font-size: $font-size-base !default;
$at-input-number-font-size-lg: $font-size-xl !default;
$at-input-number-btn-color: $color-brand !default;
$at-input-number-btn-size: 30px !default;
$at-input-number-btn-size-lg: 36px !default;
$at-input-number-width-min: 80px !default;
$at-input-number-width-min-lg: 120px !default;

/* Input */
$at-input-label-color: $color-text-base !default;
$at-input-text-color: $color-text-base !default;
$at-input-font-size: $font-size-lg !default;
$at-input-placeholder-color: $color-grey-3 !default;

/* List */
$at-list-thumb-size: 56px !default;
$at-list-arrow-color: $color-grey-3 !default;
$at-list-text-color: $color-text-base !default;
$at-list-content-color: $color-grey-2 !default;
$at-list-extra-color: $color-grey-2 !default;
$at-list-extra-width: 235px !default;

/* LoadMore */
$at-load-more-height: 80PX !default;
$at-load-more-tips-color: $color-grey-1 !default;
$at-load-more-tips-size: $font-size-lg !default;

/* Loading */
$at-loading-size: 36px !default;
$at-loading-color: $color-brand !default;

/* Message */
$at-message-color: $color-white !default;
$at-message-font-size: $font-size-base !default;
$at-message-bg-color: $color-info !default;

/* Modal */
$at-modal-width: 540px !default;
$at-modal-header-text-color: $color-text-base !default;
$at-modal-content-text-color: $color-text-base !default;
$at-modal-btn-default-color: $color-text-base !default;
$at-modal-btn-confirm-color: $color-brand !default;
$at-modal-bg-color: $color-white !default;

/* NavBar */
$at-nav-bar-title-color: $color-text-base !default;
$at-nav-bar-link-color: $color-brand !default;

/* NoticeBar */
$at-noticebar-text-color: #DE8C17 !default;
$at-noticebar-bg-color: #FCF6ED !default;
$at-noticebar-font-size: $font-size-sm !default;
$at-noticebar-icon-size: 30px !default;
$at-noticebar-btn-close-size: 32px !default;
$at-noticebar-btn-close-color: $color-grey-3 !default;

/* Pagination */
$at-pagination-margin: 40px !default;
$at-pagination-num-color: $color-text-base !default;
$at-pagination-num-font-size: $font-size-base !default;
$at-pagination-current-num-color: $color-brand !default;
$at-pagination-icon-color: $color-text-base !default;
$at-pagination-icon-font-size: 32px !default;

/* Progress */
$at-progress-height: 16px !default;
$at-progress-text-size: $font-size-sm !default;
$at-progress-icon-size: $font-size-xl !default;
$at-progress-inner-bg-color: $color-grey-6 !default;
$at-progress-bar-bg-color: $color-brand-light !default;
$at-progress-bar-bg-color-active: $color-white !default;

/* Radio */
$at-radio-title-color: $color-text-base !default;
$at-radio-title-size: $font-size-lg !default;
$at-radio-desc-color: $color-grey-2 !default;
$at-radio-desc-size: $font-size-sm !default;
$at-radio-check-color: $color-brand !default;

/* Range */
$at-range-slider-size: 28PX !default;
$at-range-rail-height: 2PX !default;
$at-range-rail-bg-color: #E9E9E9 !default;
$at-range-track-bg-color: $color-brand !default;
$at-range-slider-color: $color-white !default;
$at-range-slider-shadow: 0 0 4PX 0 rgba(0, 0, 0, 0.2) !default;

/* Rate */
$at-rate-icon-size: 20PX !default;
$at-rate-star-color: #ECECEC !default;
$at-rate-star-color-on: #FFCA3E !default;

/* SearchBar */
$at-search-bar-btn-color: $color-white !default;
$at-search-bar-btn-bg-color: $color-brand !default;

/* SegmentedControl */
$at-segmented-control-color: $color-brand !default;
$at-segmented-control-color-active: $color-white !default;
$at-segmented-control-bg-color: transparent !default;
$at-segmented-control-font-size: $font-size-base !default;

/* Slider */
$at-slider-text-color: $color-grey-2 !default;
$at-slider-text-size: $font-size-base !default;

/* Steps */
$at-steps-circle-size: 56px !default;
$at-steps-icon-size: $font-size-sm !default;
$at-steps-color: $color-white !default;
$at-steps-color-active: $color-grey-2 !default;
$at-steps-bg-color: $color-grey-4 !default;
$at-steps-bg-color-active: $color-brand !default;
$at-steps-line-color: $color-grey-3 !default;
$at-steps-title-color: $color-black-0 !default;
$at-steps-title-size: $font-size-lg !default;
$at-steps-desc-color: $color-grey-3 !default;
$at-steps-desc-size: $font-size-sm !default;

/* SwipeAction */
$at-swipe-action-color: $color-white !default;
$at-swipe-action-font-size: $font-size-base !default;
$at-swipe-action-bg-color: $color-white !default;
$at-swipe-action-option-bg-color: $color-grey-2 !default;

/* Switch */
$at-switch-title-color: $color-text-base !default;
$at-switch-title-size: $font-size-base !default;

/* TabBar */
$at-tab-bar-bg-color: $color-white !default;
$at-tab-bar-color: $color-text-base !default;
$at-tab-bar-color-active: $color-brand !default;
$at-tab-bar-font-size: $font-size-base !default;
$at-tab-bar-icon-color: $color-grey-0 !default;
$at-tab-bar-icon-font-size: 48px !default;
$at-tab-bar-icon-image-size: 50px !default;

/* Tabs */
$at-tabs-color: $color-text-base !default;
$at-tabs-color-active: $color-brand !default;
$at-tabs-font-size: $font-size-base !default;
$at-tabs-line-height: 1PX !default;
$at-tabs-underline-color: $color-grey-5 !default;
$at-tabs-bg-color: $color-bg !default;

/* Tag */
$at-tag-height: 60px !default;
$at-tag-height-sm: 40px !default;
$at-tag-color: $color-grey-1 !default;
$at-tag-color-primary: $color-grey-1 !default;
$at-tag-color-active: $color-brand-light !default;
$at-tag-color-primary-active: $color-text-base-inverse !default;
$at-tag-font-size: $font-size-base !default;
$at-tag-font-size-sm: $font-size-xs !default;
$at-tag-bg-color: $color-bg-grey !default;
$at-tag-bg-color-primary: $color-bg-grey !default;
$at-tag-bg-color-active: $color-white !default;
$at-tag-bg-color-primary-active: $at-tag-color-active !default;
$at-tag-border-color: $at-tag-bg-color !default;
$at-tag-border-color-primary: $at-tag-bg-color !default;
$at-tag-border-color-active: $at-tag-color-active !default;

/* Textarea */
$at-textarea-font-size: $font-size-lg !default;
$at-textarea-tips-color: $color-text-placeholder !default;
$at-textarea-tips-size: $font-size-base !default;

/* Timeline */
$at-timeline-offset-left: 40px !default;
$at-timeline-title-color: $color-grey-0 !default;
$at-timeline-title-font-size: $font-size-base !default;
$at-timeline-desc-color: $color-grey-1 !default;
$at-timeline-desc-font-size: $font-size-sm !default;
$at-timeline-dot-size: 24px !default;
$at-timeline-dot-color: $color-bg !default;
$at-timeline-dot-border-color: $color-brand-light !default;
$at-timeline-line-color: $color-border-lighter !default;

/**
 * Mixins
 */

/* library */
/**
 * 元素居中定位
 */
@mixin absolute-center($pos: absolute) {
  position: $pos;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/**
 * @example scss
 *
 * .element {
 *   @include clearfix;
 * }
 *
 * // CSS Output
 * .element::after {
 *   clear: both;
 *   content: '';
 *   display: block;
 * }
 */
@mixin clearfix {
  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

@mixin line($num: 1) {
  overflow: hidden;
  text-overflow: ellipsis;

  @if ($num == 1) {
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $num;

    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }
}

/**
 * 通用的遮罩
 */
@mixin overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba($color: #000, $alpha: 0.3);
}

/**
 * Mixes a color with black. It's different from darken()
 *
 * @param {color} $color
 * @param {number (percentage)} $percent [The amount of black to be mixed in]
 * @return {color}
 *
 * @example
 *   .element {
 *     background-color: shade(#ffbb52, 60%);
 *   }
 *
 *   // CSS Output
 *   .element {
 *     background-color: #664a20;
 *   }
 */
@function shade(
  $color,
  $percent
) {
  @return mix(#000, $color, $percent);
}

/**
 * Mixes a color with white. It's different from lighten()
 *
 * @param {color} $color
 * @param {number (percentage)} $percent [The amout of white to be mixed in]
 * @return {color}
 *
 * @example
 *   .element {
 *     background-color: tint(#6ecaa6 , 40%);
 *   }
 *
 *   // CSS Output
 *   .element {
 *     background-color: #a8dfc9;
 *   }
 */
@function tint(
  $color,
  $percent
) {
  @return mix(#FFF, $color, $percent);
}

@mixin display-flex {
  display: flex;
}

@mixin flex-wrap($value: nowrap) {
  flex-wrap: $value;
}

@mixin align-items($value: stretch) {
  align-items: $value;
  @if $value == flex-start {
    -webkit-box-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
  } @else {
    -webkit-box-align: $value;
  }
}

@mixin align-content($value: flex-start) {
  align-content: $value;
}

@mixin justify-content($value: flex-start) {
  justify-content: $value;
  @if $value == flex-start {
    -webkit-box-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
  } @else {
    -webkit-box-pack: $value;
  }
}

/* Flex Item */
@mixin flex($fg: 1, $fs: null, $fb: null) {
  flex: $fg $fs $fb;
  -webkit-box-flex: $fg;
}

@mixin flex-order($n) {
  order: $n;
  -webkit-box-ordinal-group: $n;
}

@mixin align-self($value: auto) {
  align-self: $value;
}

/**
 * 默认主题下 $color-border-light
 */

@mixin border-thin(
  $color: $color-border-light,
  $style: solid,
  $directions: top bottom right left,
  $width: 1px
) {
  @each $value in $directions {
    border-#{$value}: $width $color $style;
  }
}

@mixin border-thin-top(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, top, $width);
}

@mixin border-thin-left(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, left, $width);
}

@mixin border-thin-right(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, right, $width);
}

@mixin border-thin-bottom(
  $color: $color-border-light,
  $style: solid,
  $width: 1px
) {
  @include border-thin($color, $style, bottom, $width);
}

/**
 * 点击态
 */
@mixin active {
  transition: background-color 0.3s;

  &:active {
    background-color: $color-grey-5;
  }
}

/**
 * 禁止态
 */
@mixin disabled {
  color: #bbb;
}

/**
 * 设置placeholder 颜色
 */
@mixin placeholder($color: $color-grey-3) {
  @at-root .placeholder {
    color: $color;
  }

  &::placeholder {
    color: $color;
  }
}

// 修复小元素文本垂直居中
@mixin alignhack($position: before, $margintop: 1px) {
  &::#{$position} {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    margin-top: $margintop;
  }
}

/**
 * 默认主题下 $color-border-light
 */

@mixin hairline-common() {
  content: '';
  position: absolute;
  transform-origin: center;
  box-sizing: border-box;
  pointer-events: none;
}

@mixin hairline-base(
  $color: $color-border-light,
  $style: solid
) {
  @include hairline-common();

  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  border: 0 $style $color;
  transform: scale(0.5);
}

@mixin hairline-surround(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-width: $width;
  }
}

@mixin hairline-top(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-top-width: $width;
  }
}

@mixin hairline-bottom(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-bottom-width: $width;
  }
}

@mixin hairline-left(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-left-width: $width;
  }
}

@mixin hairline-right(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-right-width: $width;
  }
}

@mixin hairline-top-bottom(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-top-width: $width;
    border-bottom-width: $width;
  }
}

@mixin hairline-bottom-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $left: 0
) {
  position: relative;

  &::after {
    @include hairline-common();

    top: auto;
    left: $left;
    right: 0;
    bottom: 0;
    transform: scaleY(0.5);
    border-bottom: $width $style $color;
  }
}

@mixin hairline-top-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $left: 0
) {
  position: relative;

  &::before {
    @include hairline-common();

    top: 0;
    left: $left;
    right: 0;
    bottom: auto;
    transform: scaleY(0.5);
    border-top: $width $style $color;
  }
}

@mixin hairline-left-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $top: 0
) {
  position: relative;

  &::after {
    @include hairline-common();

    top: $top;
    left: 0;
    right: auto;
    bottom: 0;
    transform: scaleX(0.5);
    border-left: $width $style $color;
  }
}

@mixin hairline-right-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $top: 0
) {
  position: relative;

  &::after {
    @include hairline-common();

    top: $top;
    left: auto;
    right: 0;
    bottom: 0;
    transform: scaleX(0.5);
    border-right: $width $style $color;
  }
}



$at-badge-border-radius: $at-badge-font-size * 0.7;
$at-badge-box-shadow: 0 4px 8px 0 rgba($color: $at-badge-bg-color, $alpha: 0.2);

.at-badge {
  position: relative;
  display: inline-block;
  font-size: 0;
  vertical-align: middle;

  /* elements */
  &__dot {
    position: absolute;
    right: -6px;
    top: -6px;
    width: $at-badge-dot-size;
    height: $at-badge-dot-size;
    border-radius: 50%;
    overflow: hidden;
    background: $at-badge-bg;
    box-shadow: $at-badge-box-shadow;
  }

  &__num {
    position: absolute;
    padding: 0 $spacing-h-sm;
    top: -$at-badge-border-radius;
    right: -6PX;
    color: $at-badge-color;
    font-size: $at-badge-font-size;
    line-height: 1.4;
    border-radius: $at-badge-border-radius $at-badge-border-radius $at-badge-border-radius 0;
    background: $at-badge-bg;
    box-shadow: $at-badge-box-shadow;
    transform: translate(50%, 0);
    overflow: hidden;
    z-index: 1;
  }
}
$nav-height: 48px;
/**
 * Taro UI - Default variables
 */

/* Variables */
@import './variables/default.scss';

/* Mixin */
@import './mixins/index.scss';

/* Components */
@import './components/index.scss';
