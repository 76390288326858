@import '../variables/default.scss';
@import '../mixins/index.scss';

$component: '.at-switch';

#{$component} {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-bg;
  margin-left: $spacing-h-xl;
  padding: $spacing-v-lg $spacing-h-xl $spacing-v-lg 0;
  margin-bottom: 1PX;
  @include hairline-bottom();

  /* elements */
  &__title {
    flex: 6;
    color: $at-switch-title-color;
    font-size: $at-switch-title-size;
  }

  &__container {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    position: relative;
    flex: 2;
    background-color: $color-bg;
  }

  &__switch {
    display: inline-block;
  }

  &__mask {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zindex-form;
  }

  /* modifiers */
  &--disabled {
    #{$component}__switch {
      opacity: $opacity-disabled;
    }

    #{$component}__mask {
      display: block;
    }
  }

  &--without-border {
    &::after {
      border: none;
    }
  }
}
