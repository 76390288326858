//淡入
.fade-in {
  animation-name: fadeIn; /* 动画名称 */
  animation-duration: 0.5s; /* 动画持续时间 */
  animation-iteration-count: 1; /* 动画次数 */
  animation-delay: 0s; /* 延迟时间 */
  @keyframes fadeIn {
    0% {
      opacity: 0; /* 初始状态 透明度为0 */
    }

    50% {
      opacity: 0.5; /* 中间状态 透明度为0 */
    }

    100% {
      opacity: 1; /* 结尾状态 透明度为1 */
    }
  }
}

//淡出
.fade-out {
  animation-name: fadeOut; /* 动画名称 */
  animation-duration: 0.7s; /* 动画持续时间 */
  animation-iteration-count: 1; /* 动画次数 */
  animation-delay: 0s; /* 延迟时间 */
  @keyframes fadeOut {
    0% {
      opacity: 1; /* 初始状态 透明度为0 */
    }

    50% {
      opacity: 0.5; /* 中间状态 透明度为0 */
    }

    100% {
      opacity: 0; /* 结尾状态 透明度为1 */
    }
  }
}

// 从底部弹出
.fade-in-up {
  animation-name: fadeInUp;
  animation-duration: 0.3s; /* 动画持续时间 */
  animation-iteration-count: 1; /* 动画次数 */
  animation-delay: 0s; /* 延迟时间 */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

// 从底部收起
.fade-in-down {
  animation-name: fadeInDown;
  animation-duration: 0.7s; /* 动画持续时间 */
  animation-iteration-count: 1; /* 动画次数 */
  animation-delay: 0s; /* 延迟时间 */
  @keyframes fadeInDown {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  }
}

// 筛选框从顶部弹出
.fade-out-up {
  animation-name: fadeOutUp;
  animation-duration: 0.3s; /* 动画持续时间 */
  animation-iteration-count: 1; /* 动画次数 */
  animation-delay: 0s; /* 延迟时间 */
  @keyframes fadeOutUp {
    from {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }
  }
}

// 筛选框从顶部收起
.fade-out-down {
  animation-name: fadeOutDown;
  animation-duration: 0.3s; /* 动画持续时间 */
  animation-iteration-count: 1; /* 动画次数 */
  animation-delay: 0s; /* 延迟时间 */
  @keyframes fadeOutDown {
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    to {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }
  }
}

//顺时针旋转180
.rote-in {
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-delay: 0s;
  animation-name: roteOut;
  transform: rotate3d(0, 0, 1, 0deg);
  @keyframes roteIn {
    from {
      transform: rotate3d(0, 0, 1, 0deg);
    }

    to {
      transform: rotate3d(0, 0, 1, 180deg);
    }
  }
}

//逆时针旋转180
.rote-out {
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-delay: 0s;
  animation-name: roteIn;
  transform: rotate3d(0, 0, 1, 180deg);
  @keyframes roteOut {
    from {
      transform: rotate3d(0, 0, 1, 180deg);
    }

    to {
      transform: rotate3d(0, 0, 1, 0deg);
    }
  }
}

// 右滑弹出
.fade-in-right {
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-delay: 0s;
  animation-name: fadeInRight;
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

// 右滑收起
.fade-out-right {
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-delay: 0s;
  animation-name: fadeOutRight;
  @keyframes fadeOutRight {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  }
}
