@import '../variables/default.scss';
@import '../mixins/index.scss';

.at-timeline-item {
  position: relative;
  padding: 0 0 $spacing-v-sm;

  /* elements */
  &__content {
    margin-left: $at-timeline-offset-left;
    min-height: 56px;
    color: $at-timeline-title-color;
    font-size: $at-timeline-title-font-size;
    line-height: $line-height-zh;
    text-align: left;

    &--sub {
      color: $at-timeline-desc-color;
      font-size: $at-timeline-desc-font-size;
    }
  }

  &__dot,
  &__icon {
    position: absolute;
    left: 0;
    top: 5px;
    width: $at-timeline-dot-size;
    height: $at-timeline-dot-size;
    font-size: 0;
    text-align: center;
    vertical-align: middle;
    border-radius: $border-radius-circle;
    background: $at-timeline-dot-color;
    box-sizing: border-box;
    z-index: 1;

    .at-icon {
      font-size: $at-timeline-dot-size;
    }
  }

  &__dot {
    &::after {
      content: '';
      display: inline-block;
      box-sizing: border-box;
      width: $at-timeline-dot-size;
      height: $at-timeline-dot-size;
      border: 1PX solid transparent;
      border-radius: $border-radius-circle;
      border-color: $at-timeline-dot-border-color;
    }

    &.at-timeline-item__icon {
      &::after {
        border-color: transparent;
      }
    }
  }

  &__icon {
    color: $at-timeline-dot-border-color;
  }

  &__tail {
    position: absolute;
    top: $at-timeline-dot-size / 2;
    bottom: -$at-timeline-dot-size / 2;
    left: $at-timeline-dot-size / 2 - 2px;
    border-left: 1PX solid $at-timeline-line-color;
  }

  /* modifiers */
  &--green {
    .at-timeline-item__icon {
      color: $color-success;
    }

    .at-timeline-item__dot {
      &::after {
        border-color: $color-success;
      }
    }
  }

  &--red {
    .at-timeline-item__icon {
      color: $color-error;
    }

    .at-timeline-item__dot {
      &::after {
        border-color: $color-error;
      }
    }
  }

  &--yellow {
    .at-timeline-item__icon {
      color: $color-warning;
    }

    .at-timeline-item__dot {
      &::after {
        border-color: $color-warning;
      }
    }
  }
}

.at-timeline {
  .at-timeline-item:last-child {
    .at-timeline-item__tail {
      display: none;
    }
  }

  &--pending {
    .at-timeline-item:nth-last-child(2) {
      .at-timeline-item__content {
        min-height: 80px;
      }

      .at-timeline-item__tail {
        border-left-style: dotted;
      }
    }
  }
}
