@import '../variables/default.scss';
@import '../mixins/index.scss';

.at-swipe-action {
  position: relative;
  overflow: hidden;

  /* elements */
  &__content {
    position: relative;
    font-size: $font-size-lg;
    background-color: $at-swipe-action-bg-color;
    z-index: 2;

    &.animtion {
      transition: transform 300ms $timing-func;
    }
  }

  &__option {
    @include active;
    @include align-items(center);

    display: inline-flex;
    padding: 0 $spacing-h-xl;
    height: 100%;
    color: $at-swipe-action-color;
    font-size: $at-swipe-action-font-size;
    text-align: center;
    background-color: $at-swipe-action-option-bg-color;
  }

  &__options {
    @include align-items(center);

    display: inline-flex;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1;
  }
}
