/**
 * 默认主题下 $color-border-light
 */

@mixin hairline-common() {
  content: '';
  position: absolute;
  transform-origin: center;
  box-sizing: border-box;
  pointer-events: none;
}

@mixin hairline-base(
  $color: $color-border-light,
  $style: solid
) {
  @include hairline-common();

  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  border: 0 $style $color;
  transform: scale(0.5);
}

@mixin hairline-surround(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-width: $width;
  }
}

@mixin hairline-top(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-top-width: $width;
  }
}

@mixin hairline-bottom(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-bottom-width: $width;
  }
}

@mixin hairline-left(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-left-width: $width;
  }
}

@mixin hairline-right(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-right-width: $width;
  }
}

@mixin hairline-top-bottom(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX
) {
  position: relative;

  &::after {
    @include hairline-base($color, $style);

    border-top-width: $width;
    border-bottom-width: $width;
  }
}

@mixin hairline-bottom-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $left: 0
) {
  position: relative;

  &::after {
    @include hairline-common();

    top: auto;
    left: $left;
    right: 0;
    bottom: 0;
    transform: scaleY(0.5);
    border-bottom: $width $style $color;
  }
}

@mixin hairline-top-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $left: 0
) {
  position: relative;

  &::before {
    @include hairline-common();

    top: 0;
    left: $left;
    right: 0;
    bottom: auto;
    transform: scaleY(0.5);
    border-top: $width $style $color;
  }
}

@mixin hairline-left-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $top: 0
) {
  position: relative;

  &::after {
    @include hairline-common();

    top: $top;
    left: 0;
    right: auto;
    bottom: 0;
    transform: scaleX(0.5);
    border-left: $width $style $color;
  }
}

@mixin hairline-right-relative(
  $color: $color-border-light,
  $style: solid,
  $width: 1PX,
  $top: 0
) {
  position: relative;

  &::after {
    @include hairline-common();

    top: $top;
    left: auto;
    right: 0;
    bottom: 0;
    transform: scaleX(0.5);
    border-right: $width $style $color;
  }
}
