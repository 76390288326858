@import '../variables/default.scss';
@import '../mixins/index.scss';

.at-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: $at-divider-height;
  font-size: $font-size-base;
  text-align: center;

  /* elements */
  &__content {
    display: inline-block;
    position: relative;
    padding: 0 $spacing-h-lg;
    color: $at-divider-content-color;
    font-size: $at-divider-font-size;
    background: $color-bg;
    z-index: $zindex-divider + 1;
  }

  &__line {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1PX;
    background-color: $at-divider-line-color;
    z-index: $zindex-divider;
  }
}
