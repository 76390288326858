@import '../variables/default.scss';
@import '../mixins/index.scss';

$component: '.at-textarea';
$at-textarea-input-height: $at-textarea-font-size * 4;
$at-textarea-bg-color: $color-bg;

#{$component} {
  padding: $spacing-v-md;
  width: 100%;
  font-size: $at-textarea-font-size;
  line-height: $line-height-zh;
  border-radius: $border-radius-md;
  background-color: $at-textarea-bg-color;
  box-sizing: border-box;
  @include border-thin($width: 1PX);

  &__textarea {
    width: 100%;
    height: $at-textarea-input-height;
    font-size: $at-textarea-font-size;
    outline: none;
    resize: none;
    -webkit-appearance: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    border: 0;
    @include placeholder;
  }

  &__counter {
    padding-top: $spacing-v-sm;
    width: 100%;
    color: $at-textarea-tips-color;
    font-size: $at-textarea-tips-size;
    text-align: right;
    line-height: $line-height-base;
  }

  &--WEB,
  &--WEAPP {
    #{$component}__textarea {
      line-height: $line-height-base;
    }
  }

  &--error {
    #{$component}__counter {
      color: $color-error;
    }
  }
}
