@mixin line($num: 1) {
  overflow: hidden;
  text-overflow: ellipsis;

  @if ($num == 1) {
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $num;

    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }
}
