@import '../variables/default.scss';
@import '../mixins/index.scss';

$float-layout-timer: 300ms;

.at-float-layout {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: $zindex-flot-layout;
  transition: visibility $float-layout-timer cubic-bezier(0.36, 0.66, 0.04, 1);

  /* elements */
  &__overlay {
    @include overlay;

    opacity: 0;
    transition: opacity 150ms ease-in;
  }

  &__container {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: $float-layout-height-min;
    max-height: $float-layout-height-max;
    background-color: $color-bg;
    transform: translate3d(0, 100%, 0);
    transition: transform $float-layout-timer cubic-bezier(0.36, 0.66, 0.04, 1);
  }

  .layout {
    &-header {
      position: relative;
      padding: $spacing-v-md;
      line-height: $line-height-zh;
      background-color: $float-layout-header-bg-color;

      &__title {
        @include line(1);

        color: $float-layout-title-color;
        font-size: $float-layout-title-font-size;
        display: block;
        padding-right: 80px;
      }

      &__btn-close {
        position: absolute;
        padding: $spacing-h-sm;
        top: 50%;
        right: $spacing-v-md;
        width: 40px;
        height: 100%;
        line-height: 1;
        transform: translate(0, -50%);

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          display: inline-block;
          width: 36px;
          height: 2PX;
          border-radius: 1PX;
          background: $float-layout-btn-color;
        }

        &::before {
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
        }

        &::after {
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
        }
      }
    }

    &-body {
      font-size: $font-size-base;
      padding: $spacing-v-md;
      max-height: 828px;
      min-height: 514px;

      &__content {
        max-height: 798px;
        min-height: 484px;
      }
    }
  }

  /* modifiers */
  &--active {
    visibility: visible;

    .at-float-layout__overlay {
      opacity: 1;
    }

    .at-float-layout__container {
      transform: translate3d(0, 0, 0);
    }
  }
}
