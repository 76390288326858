@import '../variables/default.scss';
@import '../mixins/index.scss';

.at-load-more {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: $at-load-more-height;
  overflow: hidden;
  box-sizing: border-box;

  &__cnt {
    flex: 1;
  }

  &__tip {
    text-align: center;
    width: 100%;
    color: $at-load-more-tips-color;
    font-size: $at-load-more-tips-size;
  }
}
